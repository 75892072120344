import { closeLoadingScreen } from "src/loadingScreen";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import AppWrapper from "material-ui-theme/index";
import { Azienda } from "energix-types/src/Azienda";
import {
  Impianto,
  ReportEdistribuzione,
  ReportGSE,
} from "energix-types/src/Impianto";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import { Contatore } from "energix-types/src/Contatore";
import ClienteFinale from "src/components/pages/ClienteFinale";
import {
  CONTEXT_APP_NASCOSTA_FETCH_SUCCESS,
  fetchContextAppNascosta,
  reportSalvaLetture,
  UPLOAD_REPORT_SIGNED_XML_SUCCESS,
  uploadReportSignedXml,
} from "src/actions/apiAppNascosta";
import { Navigate, useParams } from "react-router-dom";
import { SaveDichiarazioneFunction } from "./components/features/pagina-cliente-finale/InserisciLettureDichiarazione";

type AppNascostaContext = {
  serviceLicense: Azienda;
  installation: Impianto;
  report: Dichiarazione;
  otherReports: Dichiarazione[];
  contatori: Contatore[];
  reportEdistribuzione: ReportEdistribuzione[];
  reportGSE: ReportGSE[];
};

export default function AppNascosta() {
  const dispatch = useDispatch();
  const [context, setContext] = useState<AppNascostaContext | null>(null);
  const [fetchContextError, setFetchContextError] = useState(false);
  const { token } = useParams();

  function refreshContextAppNascosta() {
    return new Promise((resolve: (_: undefined) => void) => {
      dispatch(fetchContextAppNascosta(token)).then((result: any) => {
        if (
          result.type !== CONTEXT_APP_NASCOSTA_FETCH_SUCCESS ||
          !result.response?.installation
        ) {
          setFetchContextError(true);
        } else {
          setContext(result.response);
        }
        resolve(undefined);
      });
    });
  }

  useEffect(
    () => {
      refreshContextAppNascosta().then(closeLoadingScreen);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!token || fetchContextError) {
    return <Navigate to="/" />;
  }

  function uploadSignedXml(
    idDichiarazione: number,
    file: File
  ): Promise<boolean> {
    return new Promise((resolve) => {
      dispatch(uploadReportSignedXml(token, idDichiarazione, file)).then(
        (result: any) => {
          resolve(result.type === UPLOAD_REPORT_SIGNED_XML_SUCCESS);
        }
      );
    });
  }

  const saveDichiarazione: SaveDichiarazioneFunction = async (
    payload: Dichiarazione
  ) => {
    const result = await dispatch(reportSalvaLetture(token, payload));
    if (!result.callApiSuccess) {
      return result;
    }
    refreshContextAppNascosta().then();
    return result;
  };

  if (!context) {
    return null;
  }

  return (
    <AppWrapper>
      <div className="app-main">
        <ClienteFinale
          impianto={context.installation}
          contatori={context.contatori}
          azienda={context.serviceLicense}
          dichiarazioni={context.otherReports}
          uploadSignedXml={uploadSignedXml}
          saveDichiarazione={saveDichiarazione}
          token={token}
          reportEdistribuzione={context.reportEdistribuzione}
          reportGse={context.reportGSE}
        />
      </div>
    </AppWrapper>
  );
}
