import { CALL_API } from "../middleware/api";

export const CONTEXT_FETCH_REQUEST = "CONTEXT_FETCH_REQUEST";
export const CONTEXT_FETCH_SUCCESS = "CONTEXT_FETCH_SUCCESS";
export const CONTEXT_FETCH_FAILURE = "CONTEXT_FETCH_FAILURE";

export const fetchContext = () => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CONTEXT_FETCH_REQUEST,
        CONTEXT_FETCH_SUCCESS,
        CONTEXT_FETCH_FAILURE,
      ],
      endpoint: "/api/context",
      method: "GET",
    },
  });
};
