import EDistribuzioneGraficoProduzioneDiEnergia from "src/components/features/green/EDistribuzioneGraficoProduzioneDiEnergia";
import { EDistribuzioneEnergiaMensile } from "energix-types/src/edistribuzione";
import { Card, CardContent } from "@mui/material";
import TabsCardHeader from "src/components/elements/TabsCardHeader";
import React, { useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";

export type EDistribuzioneGraficoProduzioneDiEnergiaCardProps = {
  data: EDistribuzioneEnergiaMensile[];
};

export default function EDistribuzioneGraficoProduzioneDiEnergiaCard({
  data,
}: EDistribuzioneGraficoProduzioneDiEnergiaCardProps) {
  const [selectedAnnoIndex, setSelectedAnnoIndex] = useState(0);

  const tabs = useMemo(() => {
    const labels = uniq(data.map((d) => d.anno)).sort((a, b) => b - a);
    return labels.map((label) => {
      return { label };
    });
  }, [data]);

  useEffect(() => {
    setSelectedAnnoIndex(0);
  }, [data]);

  return (
    <Card>
      {tabs.length > 1 && (
        <TabsCardHeader
          tabs={tabs}
          tab={selectedAnnoIndex}
          onChange={setSelectedAnnoIndex}
        />
      )}
      <CardContent>
        <EDistribuzioneGraficoProduzioneDiEnergia
          data={data}
          anno={tabs[selectedAnnoIndex].label}
        />
      </CardContent>
    </Card>
  );
}
