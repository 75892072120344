import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { Azienda } from "energix-types/src/Azienda";
import {
  datiByCodiceCatastale,
  datiByComune,
} from "energix-types/src/constants/datiComuniIstat";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import BaseTable from "src/components/BaseTable";
import AppControlledFormField, {
  getErrorText,
} from "src/components/elements/form/AppControllerFormField";
import ControlledAziendaPicker from "src/components/elements/form/ControlledAziendaPicker";
import NumberInput from "src/components/elements/NumberInput";
import SearchField from "src/components/elements/SearchField";
import SelectIstatComune from "src/components/elements/SelectIstatComune";
import {
  ContatoreDto,
  CreateOrUpdateImpiantoGreenDto,
  EdistribuzioneContatoreDto,
  EdistribuzioneCredenzialiDto,
  GSEContatoreDto,
  GSECredenzialiDto,
} from "src/orval/models";
import { keyBy } from "lodash";
import { DeleteIcon } from "src/components/elements/AppIcons";

export type ImpiantoGreenFormProps = {
  aziende: Azienda[];
  contatori: ContatoreDto[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  watch: UseFormWatch<CreateOrUpdateImpiantoGreenDto>;
  control: Control<CreateOrUpdateImpiantoGreenDto>;
  canChangeAzienda?: boolean;
  impiantoId?: number;
  impiantiEsistentiCard?: ReactNode;
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto[];
  gseCredenziali: GSECredenzialiDto[];
  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void;
  openGSECredenzialiDialog: (azienda: Azienda) => void;
};

export default function ImpiantoGreenForm({
  aziende,
  watch,
  contatori,
  contatoriEdistribuzione,
  contatoriGSE,
  control,
  canChangeAzienda,
  impiantoId,
  impiantiEsistentiCard,
  edistribuzioneCredenziali,
  gseCredenziali,
  openEdistribuzioneCredenzialiDialog,
  openGSECredenzialiDialog,
}: ImpiantoGreenFormProps) {
  const aziendaId = watch("license_id");
  const azienda = useMemo(() => {
    if (!aziendaId) {
      return null;
    }
    return aziende.find((a) => a.id === aziendaId) ?? null;
  }, [aziendaId, aziende]);

  const edistribuzioneCredenzialiAzienda = useMemo(() => {
    if (!azienda?.credenzialiEdistribuzioneId) {
      return null;
    }

    return (
      edistribuzioneCredenziali.find(
        (c) => c.id === azienda.credenzialiEdistribuzioneId
      ) ?? null
    );
  }, [azienda, edistribuzioneCredenziali]);

  const gseCredenzialiAzienda = useMemo(() => {
    if (!azienda?.credenzialiGSEId) {
      return null;
    }

    return (
      gseCredenziali.find((c) => c.id === azienda.credenzialiGSEId) ?? null
    );
  }, [azienda, gseCredenziali]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={1}>
        <Typography>Azienda</Typography>
        <ControlledAziendaPicker
          name="license_id"
          control={control}
          aziende={aziende}
          disabled={!canChangeAzienda}
        />
      </Stack>

      {impiantiEsistentiCard}

      {azienda && !edistribuzioneCredenzialiAzienda ? (
        <Alert
          severity="warning"
          sx={{
            "& .MuiAlert-message": {
              width: "100%",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Typography gutterBottom variant="subtitle1">
            L'azienda selezionata non ha delle credenziali e-distribuzione
            associate.
          </Typography>

          <Box sx={{ alignSelf: "flex-end" }}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() => {
                openEdistribuzioneCredenzialiDialog(azienda);
              }}
            >
              Inserisci credenziali e-distribuzione
            </Button>
          </Box>
        </Alert>
      ) : null}

      {azienda && !gseCredenzialiAzienda ? (
        <Alert
          severity="warning"
          sx={{
            "& .MuiAlert-message": {
              width: "100%",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Typography gutterBottom variant="subtitle1">
            L'azienda selezionata non ha delle credenziali GSE associate.
          </Typography>

          <Box sx={{ alignSelf: "flex-end" }}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() => {
                openGSECredenzialiDialog(azienda);
              }}
            >
              Inserisci credenziali GSE
            </Button>
          </Box>
        </Alert>
      ) : null}

      <AppControlledFormField
        name="name"
        label="Nome Impianto"
        control={control}
      />

      <Controller
        name="codiceCatastaleImpianto"
        control={control}
        render={({ field }) => {
          const datiComune = datiByCodiceCatastale[field.value || ""];
          return (
            <SelectIstatComune
              label="Comune Impianto"
              value={datiComune?.comune}
              onChange={(comune) => {
                const datiComune = datiByComune[comune || ""];
                field.onChange(datiComune?.codiceCatastale);
              }}
            />
          );
        }}
      />

      <AppControlledFormField
        name="indirizzoImpianto"
        label="Indirizzo Impianto"
        control={control}
      />
      <Controller
        name="powerWatts"
        control={control}
        render={({ field, formState }) => {
          const errorText = getErrorText(formState, "powerWatts");
          return (
            <Box>
              <Typography gutterBottom>Potenza nominale (kWp)</Typography>
              <NumberInput
                fullWidth
                size="small"
                value={field.value && field.value / 1000}
                decimalDigits={3}
                onChange={(_: any, v: number) =>
                  field.onChange(v && Math.round(v * 1000))
                }
              />
              {errorText && (
                <Typography color="error" variant="body2">
                  {errorText}
                </Typography>
              )}
            </Box>
          );
        }}
      />

      <Typography>Configurazione dei POD:</Typography>
      <Controller
        name="isImpiantoGreenContatoriManuali"
        control={control}
        render={({ field }) => {
          return (
            <RadioGroup
              value={field.value ? "manuale" : "automatica"}
              onChange={(_, v) => {
                if (v === "manuale") {
                  field.onChange(true);
                } else {
                  field.onChange(false);
                }
              }}
            >
              <FormControlLabel
                value="automatica"
                control={<Radio />}
                label={
                  <>
                    <strong>Automatica</strong>: i POD verranno rilevati
                    automaticamente dai dati scaricati da e-distribuzione e GSE
                  </>
                }
              />
              <FormControlLabel
                value="manuale"
                control={<Radio />}
                label={
                  <>
                    <strong>Manuale</strong>: dovrai inserire manualmente i POD
                    del tuo impiento. Questa funzionalità è utile nei casi in
                    cui non tutti i POD del tuo impianto vengono automaticamente
                    riconosciuti.
                  </>
                }
              />
            </RadioGroup>
          );
        }}
      />
      {azienda && (
        <Controller
          name="pods"
          control={control}
          render={({ field }) => {
            const configuraizoneManuale = watch(
              "isImpiantoGreenContatoriManuali"
            );

            if (configuraizoneManuale) {
              return (
                <Stack direction="column" spacing={1}>
                  <Typography>POD associati</Typography>
                  <PodsPickerManuale
                    contatori={contatori}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Stack>
              );
            } else {
              return (
                <Stack direction="column" spacing={1}>
                  <Typography>POD associati</Typography>
                  <PodsPicker
                    azienda={azienda}
                    impiantoId={impiantoId}
                    contatori={contatori}
                    contatoriEdistribuzione={contatoriEdistribuzione}
                    contatoriGSE={contatoriGSE}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Stack>
              );
            }
          }}
        />
      )}
    </Stack>
  );
}

const columnHelper = createColumnHelper<{ pod: string }>();

function PodsPicker({
  azienda,
  contatori,
  contatoriEdistribuzione,
  contatoriGSE,
  value,
  onChange,
  impiantoId,
}: {
  azienda: Azienda;
  value: string[] | null;
  onChange: (picked: string[]) => void;
  contatori: ContatoreDto[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  impiantoId?: number;
}) {
  const [search, setSearch] = useState("");

  const edistribuzioneByPod = useMemo(() => {
    const contatori = contatoriEdistribuzione.filter(
      (c) =>
        c.edistribuzioneCredenzialiId === azienda.credenzialiEdistribuzioneId
    );
    return keyBy(contatori, "pod");
  }, [contatoriEdistribuzione, azienda.credenzialiEdistribuzioneId]);

  const gseByPod = useMemo(() => {
    const contatori = contatoriGSE.filter(
      (c) => c.gseCredenzialiId === azienda.credenzialiGSEId
    );
    return keyBy(contatori, "pod");
  }, [contatoriGSE, azienda.credenzialiGSEId]);

  const podSelezionabili = useMemo(() => {
    const pods = new Set<string>();

    for (const c of contatoriEdistribuzione) {
      if (
        c.edistribuzioneCredenzialiId === azienda.credenzialiEdistribuzioneId
      ) {
        pods.add(c.pod);
      }
    }

    for (const c of contatoriGSE) {
      if (c.gseCredenzialiId === azienda.credenzialiGSEId) {
        pods.add(c.pod);
      }
    }

    for (const c of contatori) {
      if (c.impiantoId === impiantoId) {
        pods.add(c.pod);
      }
    }

    return Array.from(pods);
  }, [contatoriEdistribuzione, contatoriGSE, contatori, azienda, impiantoId]);

  const rows = useMemo(() => {
    return podSelezionabili.map((p) => ({ pod: p }));
  }, [podSelezionabili]);

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "_selected",
        header: "",
        cell: ({ row }) => {
          const checked = value?.includes(row.original.pod);
          return (
            <Checkbox
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...(value ?? []), row.original.pod]);
                } else {
                  onChange(
                    value ? value.filter((p) => p !== row.original.pod) : []
                  );
                }
              }}
            />
          );
        },
      }),
      columnHelper.accessor("pod", {
        header: "POD",
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>{row.original.pod}</Typography>
              {gseByPod[row.original.pod] && <Chip label="GSE" />}
              {edistribuzioneByPod[row.original.pod] && (
                <Chip label="E-Distribuzione" />
              )}
            </Stack>
          );
        },
      }),
      columnHelper.accessor(
        (c) => {
          const ec = edistribuzioneByPod[c.pod];
          if (ec) {
            return ec.matricole.join(", ");
          } else {
            return "";
          }
        },
        {
          header: "Matricole",
        }
      ),
    ];
  }, [value, onChange, edistribuzioneByPod, gseByPod]);

  return (
    <Stack direction="column">
      <SearchField search={search} setSearch={setSearch} />
      <BaseTable
        columns={columns}
        data={rows}
        globalFilter={search}
        noRowsText="Nessun POD da associare"
      />
    </Stack>
  );
}

function PodsPickerManuale({
  value,
  onChange,
}: {
  value: string[] | null;
  onChange: (picked: string[]) => void;
  contatori: ContatoreDto[];
}) {
  const [search, setSearch] = useState("");

  const rows = useMemo(() => {
    if (!value) {
      return [];
    }

    return value.map((p) => ({ pod: p }));
  }, [value]);

  const handleChangeRef = useRef<(index: number, value: string) => void>();
  const handleDeleteRef = useRef<(index: number) => void>();

  useEffect(() => {
    handleChangeRef.current = (index: number, editedValue: string) => {
      if (!value) {
        return;
      }
      const newPods: string[] = [];
      for (let i = 0; i < value.length; i++) {
        if (i === index) {
          newPods.push(editedValue);
        } else {
          newPods.push(value[i]);
        }
      }
      onChange(newPods);
    };
    handleDeleteRef.current = (index: number) => {
      const newPods: string[] = value ? [...value] : [];
      newPods.splice(index, 1);
      onChange(newPods);
    };
  }, [value, onChange]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("pod", {
        header: "POD",
        cell: ({ row }) => {
          return (
            <TextField
              size="small"
              key={row.index}
              value={row.original.pod}
              onChange={(e) => {
                handleChangeRef.current?.(row.index, e.target.value);
              }}
            />
          );
        },
      }),
      columnHelper.display({
        id: "_actions",
        header: "",
        cell: ({ row }) => {
          return (
            <IconButton
              title="Elimina"
              onClick={() => {
                handleDeleteRef.current?.(row.index);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          );
        },
      }),
    ];
  }, [handleChangeRef, handleDeleteRef]);

  return (
    <Stack direction="column">
      <SearchField search={search} setSearch={setSearch} />
      <BaseTable
        columns={columns}
        data={rows}
        globalFilter={search}
        noRowsText="Nessun POD inserito"
        paginated={false}
      />
      <Box sx={{ alignSelf: "left" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            onChange([...(value ?? []), ""]);
          }}
          sx={{ marginLeft: "24px", marginTop: 1 }}
        >
          Aggiungi POD
        </Button>
      </Box>
    </Stack>
  );
}
