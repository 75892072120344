export type ColorPair = {
  backgroundColor: string;
  color: string;
};

function c(_c: ColorPair) {
  return _c;
}

export const colors = {
  green: c({
    backgroundColor: "#DEF6DD",
    color: "#57AC57",
  }),
  orangeRed: c({
    backgroundColor: "#FFF5D6",
    color: "#EB5933",
  }),
  red: c({
    backgroundColor: "#F9DCDD",
    color: "#F54419",
  }),
  blue: c({
    backgroundColor: "#E3F1FC",
    color: "#1990FD",
  }),
  grey: c({
    backgroundColor: "#F4F6F8",
    color: "#2C3A4A",
  }),
  brown: c({
    backgroundColor: "#ffe2ad",
    color: "#664200",
  }),
  purple: c({
    backgroundColor: "#f0eefa",
    color: "#6D59D1",
  }),
  green2: c({
    backgroundColor: "#e7faf8",
    color: "#25B6A5",
  }),
} as const;

export const darkBackgroundColors = {
  green: c({
    backgroundColor: "#57AC57",
    color: "white",
  }),
  red: c({
    backgroundColor: "#EB5933",
    color: "white",
  }),
  yellow: c({
    backgroundColor: "#FFBF00",
    color: "white",
  }),
  grey: c({
    backgroundColor: "#536e8c",
    color: "white",
  }),
} as const;
