import {
  createOrUpdateImpiantoGreenSchema,
  Impianto,
} from "energix-types/src/Impianto";
import * as yup from "yup";
import { Azienda } from "energix-types/src/Azienda";
import ImpiantoGreenForm from "src/components/features/green/ImpiantoGreenForm";
import { useForm } from "react-hook-form";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import AppDialogTitle from "src/components/elements/AppDialogTitle";
import AppDialogActions from "src/components/elements/AppDialogActions";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateImpiantoGreenFunction } from "src/actions/green/updateImpiantoGreen";
import { toast } from "react-toastify";
import { getNomeImpiantoDaConfigurare } from "src/components/features/green/CreateImpiantoGreenDialog";
import {
  ContatoreDto,
  CreateOrUpdateImpiantoGreenDto,
  EdistribuzioneContatoreDto,
  EdistribuzioneCredenzialiDto,
  GSEContatoreDto,
  GSECredenzialiDto,
} from "src/orval/models";

export type EditImpiantoGreenDialogProps = {
  aziende: Azienda[];
  contatori: ContatoreDto[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  impianto: Impianto | null;
  onClose: () => void;
  updateImpiantoGreenFunction: UpdateImpiantoGreenFunction;
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto[];
  gseCredenziali: GSECredenzialiDto[];
  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void;
  openGSECredenzialiDialog: (azienda: Azienda) => void;
};

export default function EditImpiantoGreenDialog({
  aziende,
  contatori,
  contatoriEdistribuzione,
  contatoriGSE,
  impianto,
  onClose,
  updateImpiantoGreenFunction,
  edistribuzioneCredenziali,
  gseCredenziali,
  openEdistribuzioneCredenzialiDialog,
  openGSECredenzialiDialog,
}: EditImpiantoGreenDialogProps) {
  const [globalError, setGlobalError] = useState<string | undefined>();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
    setError,
    formState,
  } = useForm<CreateOrUpdateImpiantoGreenDto>({
    resolver: yupResolver(
      createOrUpdateImpiantoGreenSchema as yup.ObjectSchema<CreateOrUpdateImpiantoGreenDto>
    ),
  });

  useEffect(() => {
    if (impianto) {
      setValue("license_id", impianto.license_id);
      setValue("name", impianto.name ?? "");
      setValue("indirizzoImpianto", impianto.indirizzoImpianto);
      setValue("codiceCatastaleImpianto", impianto.codiceCatastaleImpianto);
      setValue("powerWatts", impianto.powerWatts);
      setValue(
        "isImpiantoGreenContatoriManuali",
        impianto.isImpiantoGreenContatoriManuali
      );

      const pods = contatori
        .filter((c) => c.impiantoId === impianto.id)
        .map((c) => c.pod);
      setValue("pods", pods);
    } else {
      reset();
    }
  }, [impianto, reset, setValue, contatori]);

  async function onSubmit(data: CreateOrUpdateImpiantoGreenDto) {
    if (!impianto) {
      return;
    }

    const response = await updateImpiantoGreenFunction(impianto.id, data);
    if (response.resultType === "success") {
      reset();
      onClose();
      toast.success("Impianto modificato con successo!");
    } else if (response.resultType === "validationError") {
      for (const [field, error] of Object.entries(response.errors)) {
        setError(field as keyof CreateOrUpdateImpiantoGreenDto, {
          type: "fromServer",
          message: error,
        });
      }
      setGlobalError(response.message);
    } else {
      toast.error("Errore durante la modifica dell'impianto");
    }
  }

  return (
    <Dialog open={impianto !== null} maxWidth="md" fullWidth>
      <AppDialogTitle>Modifica impianto</AppDialogTitle>
      <DialogContent>
        {impianto && !impianto.isImpiantoGreen && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Stai configurando l'impianto "
            {getNomeImpiantoDaConfigurare(impianto)}"
          </Alert>
        )}
        <ImpiantoGreenForm
          aziende={aziende}
          contatori={contatori}
          contatoriEdistribuzione={contatoriEdistribuzione}
          contatoriGSE={contatoriGSE}
          watch={watch}
          control={control}
          impiantoId={impianto?.id}
          edistribuzioneCredenziali={edistribuzioneCredenziali}
          gseCredenziali={gseCredenziali}
          openEdistribuzioneCredenzialiDialog={
            openEdistribuzioneCredenzialiDialog
          }
          openGSECredenzialiDialog={openGSECredenzialiDialog}
        />
      </DialogContent>
      <AppDialogActions>
        {!isSubmitting &&
          (globalError || Object.keys(formState.errors).length > 0) && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{ flex: 1, marginRight: 1 }}
            >
              {globalError || "Verifica i dati inseriti"}
            </Alert>
          )}
        <Button variant="outlined" disabled={isSubmitting} onClick={onClose}>
          Annulla
        </Button>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          startIcon={isSubmitting && <CircularProgress size={20} />}
        >
          Salva
        </Button>
      </AppDialogActions>
    </Dialog>
  );
}

export function useEditImpiantoGreenDialog(
  aziende: Azienda[],
  contatori: ContatoreDto[],
  contatoriEdistribuzione: EdistribuzioneContatoreDto[],
  contatoriGSE: GSEContatoreDto[],
  updateImpiantoGreenFunction: UpdateImpiantoGreenFunction,
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto[],
  gseCredenziali: GSECredenzialiDto[],
  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void,
  openGSECredenzialiDialog: (azienda: Azienda) => void
) {
  const [impianto, setImpianto] = useState<Impianto | null>(null);
  return {
    editImpiantoDialog: (
      <EditImpiantoGreenDialog
        updateImpiantoGreenFunction={updateImpiantoGreenFunction}
        aziende={aziende}
        contatori={contatori}
        contatoriEdistribuzione={contatoriEdistribuzione}
        contatoriGSE={contatoriGSE}
        impianto={impianto}
        onClose={() => {
          setImpianto(null);
        }}
        edistribuzioneCredenziali={edistribuzioneCredenziali}
        gseCredenziali={gseCredenziali}
        openEdistribuzioneCredenzialiDialog={
          openEdistribuzioneCredenzialiDialog
        }
        openGSECredenzialiDialog={openGSECredenzialiDialog}
      />
    ),
    openEditImpiantoGreenDialog: (impianto: Impianto) => {
      setImpianto(impianto);
    },
  };
}
