import React from "react";
import { Box } from "@mui/material";

export type CardTableContainerProps = {
  children?: React.ReactNode;
};

const TableContainerCard = ({ children }: CardTableContainerProps) => {
  return (
    <Box
      sx={{
        mx: -1,
        "& .MuiTablePagination-root": {
          mx: -2,
          "& .MuiToolbar-root": {
            mx: 2,
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

export default TableContainerCard;
