import React from "react";
import { Card, CardHeader, Grid, GridProps } from "@mui/material";
import NumeroScadenzeCard from "./counter-card/NumeroScadenzeCard";
import CardScadenzeImminentiPng from "../../../imgs/CardScadenzeImminenti.png";
import { NumeroScadenzeCardProps } from "./counter-card/common";
import { sum } from "lodash";
import { servizi } from "src/components/scadenze/servizi";
import { Scadenza } from "src/getters/scadenze/getScadenze";

export type ServiziInScadenzaCardProps = {
  scadenze: Scadenza[];
  adminLoggedAsUserPrefix?: string;
  header?: React.ReactNode;
  Component?: React.FC<NumeroScadenzeCardProps>;
};

const ServiziInScadenzaCard = ({
  scadenze,
  adminLoggedAsUserPrefix = "",
  header = <CardHeader title="Scadenze imminenti" sx={{ mb: 2 }} />,
  Component = NumeroScadenzeCard,
}: ServiziInScadenzaCardProps) => {
  const totalScadenze = sum(scadenze.map((s) => s.inScadenza));

  if (totalScadenze <= 0) {
    return null;
  }

  const scadenzeCards = scadenze
    .filter((scadenza) => scadenza.inScadenza > 0)
    .map((scadenza) => {
      const servizio = servizi.find(
        (servizio) => servizio.type === scadenza.servizioType
      );
      if (!servizio) {
        return null;
      }
      return (
        <Grid key={servizio.name} {...makeGridProps()}>
          <Component
            title={servizio.name}
            inScadenza={scadenza.inScadenza}
            total={scadenza.total}
            color={servizio.cardColor}
            link={adminLoggedAsUserPrefix + "/partner/" + servizio.type}
          />
        </Grid>
      );
    })
    .filter((x) => x);

  return (
    <>
      {header}
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="stretch"
        sx={{
          "> .MuiGrid-item": {
            display: "flex",
          },
          "> .MuiGrid-item > *": {
            flexGrow: 1,
          },
          mb: 4,
        }}
      >
        {/* <Grid {...makeGridProps(true, scadenzeCards.length)}>
          <Card
            sx={{
              display: "flex",
              alignItems: "stretch",
              backgroundColor: "#F7F9FA",
              p: 3,
              boxShadow: "none",
            }}
          >
            <img
              width="100%"
              src={CardScadenzeImminentiPng}
              alt=""
              style={{
                maxWidth: 220,
                margin: "auto",
              }}
            />
          </Card>
        </Grid> */}
        {scadenzeCards}
      </Grid>
    </>
  );
};

export default ServiziInScadenzaCard;

function makeGridProps(isPlaceholder = false, itemCardsLength = 0): GridProps {
  const xs = 6;
  const md = 4;
  const lg = 4;
  const xl = 3;
  return {
    item: true,
    xs,
    md,
    lg,
    xl,
    sx: isPlaceholder
      ? {
          display: {
            xs: itemCardsLength % (xs / 12) !== 0 ? "block" : "none",
            md: itemCardsLength % (md / 12) !== 0 ? "block" : "none",
            lg: itemCardsLength % (lg / 12) !== 0 ? "block" : "none",
            xl: itemCardsLength % (xl / 12) !== 0 ? "block" : "none",
          },
        }
      : {},
  };
}
