import {
  parteComune,
  stringToLength,
  intToString,
  numToString_IntegerAndDecimalPart,
  xml_numToString_IntegerAndDecimalPart,
  getRootXmlTypeForQuadro,
  getQuadroTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { each } from "lodash";
import { datiByCodiceCatastale } from "common/datiIstat";
import { getDatiQuadri } from "./lettureUtils";

function roundFloatWithFix(t) {
  /*
  example:
    > var t = 3253.14
    > t/12
    271.09499999999997
    > Math.round(t/12*100)/100
    271.09
    > (t/12).toFixed(5)
    '271.09500'
    > Math.round(parseFloat((t/12).toFixed(8))*100)/100
    271.1
  */
  return Math.round(parseFloat(t.toFixed(8)) * 100) / 100;
}

function roundFloat0DecWithFix(t) {
  /*
  example:
    > var t = 3253.14
    > t/12
    271.09499999999997
    > Math.round(t/12*100)/100
    271.09
    > (t/12).toFixed(5)
    '271.09500'
    > Math.round(parseFloat((t/12).toFixed(8))*100)/100
    271.1
  */
  return Math.round(parseFloat(t.toFixed(8)));
}

// aliquota per i primi 200.000
const K_aliquotaPerChilowattora_9_11 = 0.0125;
// aliquota per i restanti
const K_aliquotaPerChilowattora_10 = 0.0075;
// aliquota fissa per ogni mese per riga 12
const K_aliquotaFissaPerMese_12 = 4820;

const K_aliquotaPerChilowattora_1_3 = 0.0227;
const K_aliquotaPerChilowattora_2 = 0.0227;

function makeXml(report, result, quadroType) {
  const xmlInfoByKey = {};
  const infoToUpdate = [];

  result.forEach((info) => {
    const key = info.provincia; // il mese nel root del xml è il "Mese inizio attività" e è sempre 0?
    let xmlInfo = xmlInfoByKey[key];

    if (!xmlInfo) {
      xmlInfo = {
        xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
        children: [
          isPre2021(report) && {
            xmlType: "TipoRecord",
            content: getQuadroTypeForQuadro(quadroType),
          },
          {
            xmlType: isPre2021(report) ? "Provincia" : "Prov",
            content: info.provincia,
          },
          {
            xmlType: "Mese",
            content: info.meseInizioAttivita || 0,
          },
          {
            xmlType: isPre2021(report) ? "CapitoloImputazione" : "Cap",
            content: "1411",
          },
          {
            xmlType: isPre2021(report) ? "ArticoloCapitolo" : "Art",
            content: "01",
          },
        ].filter((x) => x),
        _liquidazioni: [],
      };

      xmlInfoByKey[key] = xmlInfo;
      info.xml = xmlInfo;
      infoToUpdate.push(xmlInfo);
    }

    const chilowattoraArrotondato = info.chilowattora
      ? roundFloat0DecWithFix(info.chilowattora)
      : info.chilowattora;

    const xmlObj = {
      xmlType: isPre2021(report) ? "Liquidazione" : "Liquid",
      children: [],
    };

    const progressivo = info.totale ? 14 : info.progressivoRigo;
    // se <Progressivo> ha valore :
    // - da 1 a 5, da 7 a 9: indicare il valore 0
    // - 6, 10, 11, 13: indicare il valore 1
    // - 14: indicare il valore 9
    let tipoRigo;
    switch (progressivo) {
      case 14:
        tipoRigo = 9;
        break;
      case 6:
      case 10:
      case 11:
      case 13:
        tipoRigo = 1;
        break;
      default:
        tipoRigo = 0;
        break;
    }

    xmlObj.children.push({
      xmlType: isPre2021(report) ? "Progressivo" : "Progr",
      content: progressivo,
    });
    xmlObj.children.push({
      xmlType: isPre2021(report) ? "TipoRigo" : "TipoR",
      content: tipoRigo,
    });
    if (isPre2021(report)) {
      xmlObj.children.push({
        xmlType: "ProgressivoRecord",
        content: 0,
      });
    }

    if (isPre2021(report)) {
      if (!info.totale) {
        if (info.usaAliquotaFissaPerMese) {
          // riga 6: valore vuoto??
          xmlObj.children.push({
            xmlType: "Consumi",
            content: "",
          });
          xmlObj.children.push({
            xmlType: "Aliquota",
            content: "",
          });
        } else {
          xmlObj.children.push({
            xmlType: "Consumi",
            content: chilowattoraArrotondato,
          });
          xmlObj.children.push({
            xmlType: "Aliquota",
            content: xml_numToString_IntegerAndDecimalPart(
              info.aliquotaPerChilowattora,
              1,
              7
            ),
          });
        }
      } else {
        // valore vuoto??
        xmlObj.children.push({
          xmlType: "Consumi",
          content: "",
        });
        xmlObj.children.push({
          xmlType: "Aliquota",
          content: "",
        });
      }
      xmlObj.children.push({
        xmlType: "Imposta",
        content: xml_numToString_IntegerAndDecimalPart(info.imposta, 12, 2),
      });
    } else {
      if (!info.totale) {
        if (info.usaAliquotaFissaPerMese) {
          xmlObj.children.push({
            xmlType: "Dettaglio",
            children: [
              {
                xmlType: "Qta",
                content: "",
              },
              {
                xmlType: "Aliq",
                content: "",
              },
            ],
          });
        } else {
          xmlObj.children.push({
            xmlType: "Dettaglio",
            children: [
              {
                xmlType: "Qta",
                content: chilowattoraArrotondato,
              },
              {
                xmlType: "Aliq",
                content: xml_numToString_IntegerAndDecimalPart(
                  info.aliquotaPerChilowattora,
                  1,
                  7
                ),
              },
            ],
          });
        }
        xmlObj.children.push({
          xmlType: "Totale", // della riga
          content: xml_numToString_IntegerAndDecimalPart(info.imposta, 12, 2),
        });
      } else {
        //totale
        xmlObj.children.push({
          xmlType: "Dettaglio",
          children: [
            {
              xmlType: "Qta",
              content: "",
            },
            {
              xmlType: "Aliq",
              content: "",
            },
          ],
        });
        xmlObj.children.push({
          xmlType: "Totale",
          content: xml_numToString_IntegerAndDecimalPart(info.imposta, 12, 2),
        });
      }
    }

    xmlInfo._liquidazioni.push(xmlObj);
  });

  infoToUpdate.forEach((xmlInfo) => {
    xmlInfo._liquidazioni.forEach((x) => xmlInfo.children.push(x));
    delete xmlInfo._liquidazioni;
  });
}

export default function (installation, serviceLicense, report, quadroM, fail) {
  const datiQuadri = getDatiQuadri(
    report,
    "liquidazioneAccisa",
    "liquidazione"
  );

  if (!datiQuadri || datiQuadri.length === 0) {
    if (
      installation.type === "CESSIONE_TOTALE" ||
      installation.type === "CESSIONE_PARZIALE_NON_COMMERCIALE"
    ) {
      return [];
    }

    if (!quadroM || quadroM.length === 0) {
      return [];
    }
  }

  let result = [];
  const resultByKey = {};

  let meseInizioAttivita = report.reportInfo.meseInizioAttivita || 0;
  const datiQuadriMeseInizioAttivita = getDatiQuadri(
    report,
    "meseInizioAttivitaArray",
    "meseInizioAttivita"
  );
  if (datiQuadriMeseInizioAttivita && datiQuadriMeseInizioAttivita.length) {
    const datiQuadriMeseInizioAttivitaInfo = datiQuadriMeseInizioAttivita[0];
    if (
      typeof datiQuadriMeseInizioAttivitaInfo.meseInizioAttivita === "string"
    ) {
      meseInizioAttivita =
        parseInt(datiQuadriMeseInizioAttivitaInfo.meseInizioAttivita, 10) || 0;
    }
  }

  each(
    quadroM,
    ({
      mese,
      codiceCatastaleComune,
      chilowattora,
      rigaQuadro,
      consumiUsiPropri,
      consumiUsiCommerciali,
      usaDatiQuadro,
    }) => {
      if (usaDatiQuadro) {
        chilowattora = (consumiUsiPropri || 0) + (consumiUsiCommerciali || 0);
      }
      let progressivoRigo;
      let aliquotaPerChilowattora;
      let usaAliquotaFissaPerMese = false;
      switch (rigaQuadro) {
        case 1:
        case 3:
          progressivoRigo = 1;
          aliquotaPerChilowattora = K_aliquotaPerChilowattora_1_3;
          break;
        case 2:
          progressivoRigo = 2;
          aliquotaPerChilowattora = K_aliquotaPerChilowattora_2;
          break;
        case 9:
        case 11:
          progressivoRigo = 4;
          aliquotaPerChilowattora = K_aliquotaPerChilowattora_9_11;
          break;
        case 10:
          progressivoRigo = 5;
          aliquotaPerChilowattora = K_aliquotaPerChilowattora_10;
          break;
        case 12:
          progressivoRigo = 6;
          aliquotaPerChilowattora = false;
          usaAliquotaFissaPerMese = K_aliquotaFissaPerMese_12;
          break;
        default:
          console.log(
            "Errore quadro M rigo sconosciuto per aliquota: " + rigaQuadro
          );
          return;
      }

      const key = codiceCatastaleComune + "--" + progressivoRigo;
      let info = resultByKey[key];

      if (!info) {
        info = {
          codiceCatastaleComune: codiceCatastaleComune,
          chilowattora: 0,
          progressivoRigo: progressivoRigo,
          aliquotaPerChilowattora: aliquotaPerChilowattora,
          usaAliquotaFissaPerMese,
          mesiCount: 0,
        };
        result.push(info);
        resultByKey[key] = info;
      }

      info.chilowattora += chilowattora;
      info.mesiCount++;
    }
  );

  if (datiQuadri.length) {
    // inserimento manuali quadri

    datiQuadri.forEach((riga) => {
      if (!riga) {
        return;
      }
      const { progressivo: progressivoRigo, consumi, imposta, aliquota } = riga;
      if (progressivoRigo === 14) {
        //Totale
        return;
      }

      const usaAliquotaFissaPerMese =
        progressivoRigo === 6 ||
        (progressivoRigo >= 10 && progressivoRigo <= 14);

      const data = {};
      let hasData = false;
      if (!usaAliquotaFissaPerMese) {
        if (typeof consumi === "number") {
          data.chilowattora = consumi;
          hasData = true;
        } else {
          data.chilowattora = 0;
        }
        if (typeof aliquota === "number") {
          data.aliquotaPerChilowattora = aliquota;
          hasData = true;
        } else {
          data.aliquotaPerChilowattora = 0;
        }
      }
      if (typeof imposta === "number") {
        data.imposta = imposta;
        hasData = true;
      } else {
        data.imposta = 0;
      }

      if (hasData) {
        const codiceCatastaleComune = installation.codiceCatastaleImpianto;
        const key = codiceCatastaleComune + "--" + progressivoRigo;

        let info = resultByKey[key];
        if (info) {
          result = result.filter((x) => x !== info);
        }

        info = {
          codiceCatastaleComune: codiceCatastaleComune,
          progressivoRigo: progressivoRigo,
          usaAliquotaFissaPerMese: usaAliquotaFissaPerMese,
          mesiCount: 0,
          ...data,
        };
        result.push(info);
        resultByKey[key] = info;
      }
    });
  }

  // calcola imposta + create c01

  let totaleImposta = 0;
  result.forEach((info) => {
    const chilowattoraArrotondato = info.chilowattora
      ? roundFloat0DecWithFix(info.chilowattora)
      : info.chilowattora;
    let euro;
    if (info.usaAliquotaFissaPerMese) {
      euro = roundFloatWithFix(info.mesiCount * info.usaAliquotaFissaPerMese);
    } else {
      euro = roundFloatWithFix(
        chilowattoraArrotondato * info.aliquotaPerChilowattora
      );
    }

    info.imposta = euro;

    var c = datiByCodiceCatastale[info.codiceCatastaleComune];
    info.comune = c.comuneRaw;
    info.provincia = c.provincia;
    info.meseInizioAttivita = meseInizioAttivita;

    totaleImposta += euro;

    info.c01 =
      parteComune("P", installation, serviceLicense, report, 0, fail) +
      "P" +
      "1411" +
      "01" +
      intToString(info.progressivoRigo, 2) +
      "0" + //tipo rigo
      "00" +
      // diverso per rigo 6
      (info.usaAliquotaFissaPerMese
        ? "+"
        : chilowattoraArrotondato >= 0
        ? "+"
        : "-") +
      (info.usaAliquotaFissaPerMese
        ? intToString(0, 13)
        : intToString(Math.abs(chilowattoraArrotondato), 13)) +
      (info.usaAliquotaFissaPerMese
        ? intToString(0, 1) + intToString(0, 7)
        : numToString_IntegerAndDecimalPart(
            info.aliquotaPerChilowattora,
            1,
            7
          )) +
      // imposta
      (info.imposta >= 0 ? "+" : "-") +
      numToString_IntegerAndDecimalPart(info.imposta, 12, 2);
  });

  // add totale
  var c = datiByCodiceCatastale[installation.codiceCatastaleImpianto]; // questo presuppone che sia sempre uguale la provincia... potrebbe dare problemi
  const totalInfo = {
    totale: true,
    progressivoRigo: 14,
    imposta: totaleImposta,
    provincia: c.provincia,
    meseInizioAttivita: meseInizioAttivita,
  };
  totalInfo.c01 =
    parteComune("P", installation, serviceLicense, report, 0, fail) +
    "P" +
    "1411" +
    "01" +
    "14" +
    "9" + //tipo rigo
    "00" +
    "+" +
    intToString(0, 13) +
    intToString(0, 1) +
    intToString(0, 7) +
    (totalInfo.imposta >= 0 ? "+" : "-") +
    numToString_IntegerAndDecimalPart(totalInfo.imposta, 12, 2);

  result.push(totalInfo);

  //xml
  makeXml(report, result, "P");

  return result;
}
