import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Azienda } from "energix-types/src/Azienda";
import {
  createOrUpdateImpiantoGreenSchema,
  Impianto,
} from "energix-types/src/Impianto";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CreateImpiantoGreenFunction } from "src/actions/green/createImpiantoGreen";
import AppDialogActions from "src/components/elements/AppDialogActions";
import AppDialogTitle from "src/components/elements/AppDialogTitle";
import ImpiantoGreenForm from "src/components/features/green/ImpiantoGreenForm";
import {
  ContatoreDto,
  CreateOrUpdateImpiantoGreenDto,
  EdistribuzioneContatoreDto,
  EdistribuzioneCredenzialiDto,
  GSEContatoreDto,
  GSECredenzialiDto,
} from "src/orval/models";

export type CreateImpiantoGreenDialogProps = {
  impianti: Impianto[];
  aziende: Azienda[];
  contatori: ContatoreDto[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  onClose: () => void;
  open: boolean;
  createImpiantoGreenFunction: CreateImpiantoGreenFunction;
  openEditImpiantoGreenDialog: (impianto: Impianto) => void;
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto[];
  gseCredenziali: GSECredenzialiDto[];
  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void;
  openGSECredenzialiDialog: (azienda: Azienda) => void;
};

export default function CreateImpiantoGreenDialog({
  impianti,
  aziende,
  contatori,
  contatoriEdistribuzione,
  contatoriGSE,
  onClose,
  open,
  createImpiantoGreenFunction,
  openEditImpiantoGreenDialog,
  edistribuzioneCredenziali,
  gseCredenziali,
  openEdistribuzioneCredenzialiDialog,
  openGSECredenzialiDialog,
}: CreateImpiantoGreenDialogProps) {
  const [globalError, setGlobalError] = useState<string | undefined>();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setError,
    formState,
  } = useForm<CreateOrUpdateImpiantoGreenDto>({
    resolver: yupResolver(
      createOrUpdateImpiantoGreenSchema as yup.ObjectSchema<CreateOrUpdateImpiantoGreenDto>
    ),
    defaultValues: {
      pods: [],
    },
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  async function onSubmit(data: CreateOrUpdateImpiantoGreenDto) {
    const response = await createImpiantoGreenFunction(data);
    if (response.resultType === "success") {
      reset();
      onClose();
      toast.success("Impianto creato con successo!");
    } else if (response.resultType === "validationError") {
      for (const [field, error] of Object.entries(response.errors)) {
        setError(field as keyof CreateOrUpdateImpiantoGreenDto, {
          type: "fromServer",
          message: error,
        });
      }
      setGlobalError(response.message);
    } else {
      toast.error("Errore durante la creazione dell'impianto");
    }
  }

  const pickedAziendaId = watch("license_id");
  const impiantiEsistentiNonConfigurati = useMemo(() => {
    if (!pickedAziendaId) {
      return [];
    }
    return impianti.filter(
      (i) => i.license_id === pickedAziendaId && !i.isImpiantoGreen
    );
  }, [impianti, pickedAziendaId]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <AppDialogTitle>Crea impianto</AppDialogTitle>
      <DialogContent>
        <ImpiantoGreenForm
          canChangeAzienda
          aziende={aziende}
          contatori={contatori}
          contatoriEdistribuzione={contatoriEdistribuzione}
          contatoriGSE={contatoriGSE}
          watch={watch}
          control={control}
          edistribuzioneCredenziali={edistribuzioneCredenziali}
          gseCredenziali={gseCredenziali}
          openEdistribuzioneCredenzialiDialog={
            openEdistribuzioneCredenzialiDialog
          }
          openGSECredenzialiDialog={openGSECredenzialiDialog}
          impiantiEsistentiCard={
            impiantiEsistentiNonConfigurati.length > 0 && (
              <Alert
                severity="warning"
                sx={{ "& .MuiAlert-message": { width: "100%" } }}
              >
                <Typography gutterBottom variant="subtitle1">
                  Attenzione, questa azienda ha già degli impianti non
                  configurati:
                </Typography>

                <List>
                  {impiantiEsistentiNonConfigurati.map((impianto, i) => (
                    <ListItem
                      divider={i !== impiantiEsistentiNonConfigurati.length - 1}
                      key={impianto.id}
                      secondaryAction={
                        <Button
                          variant="contained"
                          size="small"
                          color="warning"
                          onClick={() => {
                            onClose();
                            openEditImpiantoGreenDialog(impianto);
                          }}
                        >
                          Configura
                        </Button>
                      }
                    >
                      <ListItemText
                        primary={getNomeImpiantoDaConfigurare(impianto)}
                      />
                    </ListItem>
                  ))}
                </List>
              </Alert>
            )
          }
        />
      </DialogContent>
      <AppDialogActions>
        {!isSubmitting &&
          (globalError || Object.keys(formState.errors).length > 0) && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{ flex: 1, marginRight: 1 }}
            >
              {globalError || "Verifica i dati inseriti"}
            </Alert>
          )}
        <Button variant="outlined" disabled={isSubmitting} onClick={onClose}>
          Annulla
        </Button>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          startIcon={isSubmitting && <CircularProgress size={20} />}
        >
          Crea
        </Button>
      </AppDialogActions>
    </Dialog>
  );
}

export function useCreateImpiantoGreenDialog(
  impianti: Impianto[],
  aziende: Azienda[],
  contatori: ContatoreDto[],
  contatoriEdistribuzione: EdistribuzioneContatoreDto[],
  contatoriGSE: GSEContatoreDto[],
  createImpiantoGreenFunction: CreateImpiantoGreenFunction,
  openEditImpiantoGreenDialog: (impianto: Impianto) => void,
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto[],
  gseCredenziali: GSECredenzialiDto[],
  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void,
  openGSECredenzialiDialog: (azienda: Azienda) => void
) {
  const [open, setOpen] = useState(false);
  return {
    createImpiantoDialog: (
      <CreateImpiantoGreenDialog
        impianti={impianti}
        createImpiantoGreenFunction={createImpiantoGreenFunction}
        aziende={aziende}
        contatori={contatori}
        contatoriEdistribuzione={contatoriEdistribuzione}
        contatoriGSE={contatoriGSE}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        openEditImpiantoGreenDialog={openEditImpiantoGreenDialog}
        edistribuzioneCredenziali={edistribuzioneCredenziali}
        gseCredenziali={gseCredenziali}
        openEdistribuzioneCredenzialiDialog={
          openEdistribuzioneCredenzialiDialog
        }
        openGSECredenzialiDialog={openGSECredenzialiDialog}
      />
    ),
    openCreateImpiantoGreenDialog: () => {
      setOpen(true);
    },
  };
}

export function getNomeImpiantoDaConfigurare(impianto: Impianto) {
  return [
    impianto.name || impianto.installationCode || "",
    `(${impianto.nomeSoggetto})`,
  ].join(" ");
}
