import {
  parteComune,
  stringToLength,
  intToString,
  numToString_IntegerAndDecimalPart,
  xml_numToString_IntegerAndDecimalPart,
  getQuadroTypeForQuadro,
  getRootXmlTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { find } from "lodash";
import { datiByCodiceCatastale } from "common/datiIstat";
import { applyOverrideQuadroQ } from "./quadriUtils";
import { getDatiQuadri } from "./lettureUtils";

function roundFloatWithFix(t) {
  /*
  example:
    > var t = 3253.14
    > t/12
    271.09499999999997
    > Math.round(t/12*100)/100
    271.09
    > (t/12).toFixed(5)
    '271.09500'
    > Math.round(parseFloat((t/12).toFixed(8))*100)/100
    271.1
  */
  return Math.round(parseFloat(t.toFixed(8)) * 100) / 100;
}

export default function (
  installation,
  serviceLicense,
  report,
  quadroP,
  meseConguaglio,
  fail
) {
  const totaleQuadroP = find(quadroP, (obj) => obj.totale);
  if (!totaleQuadroP) {
    return [];
  }

  if (totaleQuadroP.imposta < 0) {
    if (fail) {
      throw new Error(
        "L'importo delle accise è minore di zero. Questo caso non è stato implementato"
      );
    }
  }

  const datiQuadri = getDatiQuadri(
    report,
    "riepilogoSaldoAccisa",
    "riepilogoSaldo"
  );
  const datiQuadriX = getDatiQuadri(
    report,
    "riepilogoSaldoAccisaX",
    "riepilogoSaldo"
  );
  const getFromDatiQuadri = (progressivo) => {
    const d = (datiQuadri || []).filter((x) => {
      return x && x.progressivo === progressivo;
    });
    return d[0] && d[0].importo;
  };
  const getFromDatiQuadriX = (progressivo) => {
    const d = (datiQuadriX || []).filter((x) => {
      return x && x.progressivo === progressivo;
    });
    return d[0] && d[0].importo;
  };

  const accontiAnnoPrecedente = report.reportInfo.accontiAnnoPrecedente || [];
  let imposteAnnoPrecedente = accontiAnnoPrecedente.reduce(
    (s, v) => s + (v || 0),
    0
  );

  if (report.anno === 2021) {
    // 10% da maggio (5) a settembre (9)
    const importoSostegno = accontiAnnoPrecedente.reduce((s, v, i) => {
      if (i >= 5 && i <= 9) {
        return s + (v || 0) * 0.1;
      }
      return s;
    }, 0);
    imposteAnnoPrecedente -= roundFloatWithFix(importoSostegno);
  }

  // quadro manuale
  const imposteAnnoPrecedenteFDX =
    !isPre2021(report) && report.anno - 1 === 2021 // solo per anno 2021 - transizione da provincia a ambito
      ? getFromDatiQuadriX(2)
      : null;
  if (typeof imposteAnnoPrecedenteFDX === "number") {
    imposteAnnoPrecedente = imposteAnnoPrecedenteFDX;
  } else {
    const imposteAnnoPrecedenteFDQ = getFromDatiQuadri(2);
    if (typeof imposteAnnoPrecedenteFDQ === "number") {
      imposteAnnoPrecedente = imposteAnnoPrecedenteFDQ;
    }
  }

  let accisaADebito = totaleQuadroP.imposta - imposteAnnoPrecedente;
  let accisaACredito = 0;
  if (accisaADebito <= 0) {
    accisaACredito = -accisaADebito;
    accisaADebito = 0;
  }

  let acconti = [];

  const rateoAccontoDivisore =
    parseInt(report.reportInfo.rateoAccontoDivisore) || 12;
  const rateoAccontoOgniNumeroMesi =
    parseInt(report.reportInfo.rateoAccontoOgniNumeroMesi) || 1; // 1 mensile, 6 semestrale, 12 annuale
  const meseConguaglio2 = parseInt(report.reportInfo.meseConguaglio2) || 11; // 2° rata per semestrale
  const impiantoCessato = report.reportInfo.impiantoCessato
    ? parseInt(report.reportInfo.impiantoCessato, 10)
    : null; // mese in cui è cessato

  const totaleRateiAnnoSuccessivo =
    (totaleQuadroP.imposta / rateoAccontoDivisore) * 12;

  if (!impiantoCessato) {
    if (rateoAccontoOgniNumeroMesi === 1) {
      // normale: mensile

      // calcolo degli acconti
      const numeroMesiConValoreAnnoPrecedente = meseConguaglio - 1;
      const numeroMesiBase = 12 - meseConguaglio;
      const accontoBase = roundFloatWithFix(totaleRateiAnnoSuccessivo / 12);
      const accontoDicembreAnnoPrecedente = accontiAnnoPrecedente[12] || 0;

      // let accontoRestante = totaleRateiAnnoSuccessivo - (accontoBase * numeroMesiBase) - (accontoDicembreAnnoPrecedente * numeroMesiConValoreAnnoPrecedente)
      // modifica 2021, il valore va calcolato in modo diverso... per la questione di arrotondamenti
      let accontoRestante =
        accontoBase +
        (accontoBase - accontoDicembreAnnoPrecedente) *
          numeroMesiConValoreAnnoPrecedente;

      // es con meseConguaglio = marzo: gennaio e febbraio ha pagato l'acconto dell'anno precedente
      for (let i = 1; i < meseConguaglio; i++) {
        acconti.push(accontoDicembreAnnoPrecedente);
      }

      // es con meseConguaglio = marzo: a marzo paga la differenza tra il totale e gli acconti degli altri mesi
      if (accontoRestante >= 0) {
        acconti.push(accontoRestante);
        accontoRestante = 0;
      } else {
        acconti.push(0);
      }

      // es con meseConguaglio = marzo: acconto base usato da aprile a dicembre
      for (let i = meseConguaglio + 1; i <= 12; i++) {
        let vMese = accontoBase + accontoRestante;
        if (vMese < 0) {
          accontoRestante = vMese;
          vMese = 0;
        } else {
          accontoRestante = 0;
        }
        acconti.push(vMese);
      }
    } else if (rateoAccontoOgniNumeroMesi === 6) {
      // semestrale
      const accontoBase = roundFloatWithFix(totaleRateiAnnoSuccessivo / 2);

      for (let i = 1; i <= 12; i++) {
        if (i === meseConguaglio) {
          acconti.push(accontoBase);
        } else if (i === meseConguaglio2) {
          acconti.push(accontoBase);
        } else {
          acconti.push(0);
        }
      }
    } else {
      // annuale: tutto in un mese
      const accontoBase = roundFloatWithFix(totaleRateiAnnoSuccessivo / 1);

      for (let i = 1; i <= 12; i++) {
        if (i === meseConguaglio) {
          acconti.push(accontoBase);
        } else {
          acconti.push(0);
        }
      }
    }
  }

  let c = datiByCodiceCatastale[installation.codiceCatastaleImpianto];

  let result = [];

  // totale imposte
  result.push({
    codiceCatastaleComune: installation.codiceCatastaleImpianto,
    comune: c.comuneRaw,
    provincia: c.provincia,
    progressivoRigo: 1,
    importo: totaleQuadroP.imposta,
  });

  // totale accise anno precedente
  result.push({
    progressivoRigo: 2,
    importo: imposteAnnoPrecedente,
  });

  // conguaglio accisa a debito
  if (accisaADebito > 0) {
    result.push({
      progressivoRigo: 3,
      importo: accisaADebito,
    });
  }

  // conguaglio accisa a credito
  if (accisaACredito > 0) {
    result.push({
      progressivoRigo: 4,
      importo: accisaACredito,
    });
  }

  if (!impiantoCessato) {
    acconti = applyOverrideQuadroQ(report, acconti);

    // override acconti
    acconti = acconti.map((v, i) => {
      const progressivoRigo = 900 + i + 1;
      const accontoFDQ = getFromDatiQuadri(progressivoRigo);
      if (typeof accontoFDQ === "number") {
        v = accontoFDQ;
      }
      return v;
    });

    const totaleRateiAnnoSuccessivoCalcolato = acconti.reduce((prev, v) => {
      return prev + v;
    }, 0);

    // totale acconti
    result.push({
      progressivoRigo: 5,
      importo: totaleRateiAnnoSuccessivoCalcolato,
    });
  }

  // credito pregresso
  if (report.anno - 1 >= 2022) {
    result.push({
      progressivoRigo: 6,
      importo: report.reportInfo.creditoPregressoNonUtilizzato || 0,
    });
  }

  if (!impiantoCessato) {
    // acconti per mese
    acconti.forEach((v, i) => {
      result.push({
        progressivoRigo: 900 + i + 1,
        importo: v,
      });
    });
  }

  // create c01

  result.forEach((info) => {
    info.c01 =
      parteComune("Q", installation, serviceLicense, report, 0, fail) +
      "Q" +
      "1411" +
      "01" +
      intToString(info.progressivoRigo, 3) +
      "+" +
      numToString_IntegerAndDecimalPart(info.importo, 12, 2);
  });

  const ambito = c.provincia; // mi pare che per noi è sempre cosi, non si raggruppano in ambiti perche
  // abbiamo dato per scontato che la provincia del quadro Q è la stessa della ditta

  // xml
  const xmlObj = {
    xmlType: getRootXmlTypeForQuadro("Q", isPre2021(report)),
    children: [
      isPre2021(report) && {
        xmlType: "TipoRecord",
        content: getQuadroTypeForQuadro("Q"),
      },
      isPre2021(report)
        ? {
            xmlType: "Provincia",
            content: c.provincia,
          }
        : {
            xmlType: "Ambito",
            content: ambito,
          },
      isPre2021(report) && {
        xmlType: "Mese",
        content: 0,
      },
      {
        xmlType: isPre2021(report) ? "CapitoloImputazione" : "Cap",
        content: "1411",
      },
      {
        xmlType: isPre2021(report) ? "ArticoloCapitolo" : "Art",
        content: "01",
      },
      report.anno - 1 === 2020 && {
        //Scelta adesione al Conguaglio Rateale (DL 34/2020)
        // valori ammessi: SI , NO
        // obblibatorio per anno 2020 obbligatoriamente assente altri anni
        xmlType: "ConguaglioRatealeDL34-2020",
        content: report.reportInfo.conguaglioRateale2020 ? "SI" : "NO",
      },
    ].filter((x) => x),
  };

  // solo per anno 2021 - transizione da provincia a ambito
  const xmlObjQuadroX = !isPre2021(report) &&
    report.anno - 1 === 2021 && {
      xmlType: "X",
      children: [
        {
          xmlType: "Prov",
          content: c.provincia,
        },
        {
          xmlType: "Cap",
          content: "1411",
        },
        {
          xmlType: "Art",
          content: "01",
        },
      ],
    };

  result.forEach((info) => {
    let prefixProgRigo = false;
    const infoXml = {
      xmlType: isPre2021(report) ? "RiepilogoSaldo" : "RiepSaldo",
      children: [
        {
          xmlType: isPre2021(report) ? "Progressivo" : "Progr",
          content: info.progressivoRigo,
        },
        {
          xmlType: isPre2021(report) ? "Importo" : "Imp",
          content: xml_numToString_IntegerAndDecimalPart(info.importo, 12, 2),
        },
      ],
    };

    // se nell'anno di transizione (con quadro x) allora non fare Q2-Q3
    if (
      !xmlObjQuadroX ||
      (info.progressivoRigo !== 2 && info.progressivoRigo !== 3)
    ) {
      xmlObj.children.push(infoXml);
    } else {
      prefixProgRigo = "X";
    }

    if (xmlObjQuadroX) {
      if (info.progressivoRigo < 900) {
        let value = getFromDatiQuadriX(info.progressivoRigo);

        if (typeof value !== "number") {
          if (info.progressivoRigo === 5) {
            value = report.reportInfo.creditoPregressoNonUtilizzato || 0;
          } else {
            value = info.importo;
          }
        }

        xmlObjQuadroX.children.push({
          xmlType: "RiepSaldo",
          children: [
            {
              xmlType: "Progr",
              content: info.progressivoRigo,
            },
            {
              xmlType: isPre2021(report) ? "Importo" : "Imp",
              content: xml_numToString_IntegerAndDecimalPart(value, 12, 2),
            },
          ],
        });
      }
    }

    if (prefixProgRigo) {
      info.progressivoRigo = prefixProgRigo + info.progressivoRigo;
    }
  });

  result[0].xml = xmlObj;
  if (xmlObjQuadroX) {
    result[1].xml = xmlObjQuadroX;
  }

  return result;
}
