import { IconButton, InputAdornment, TextField } from "@mui/material";
import { CloseIcon, SearchIcon } from "./AppIcons";
import React from "react";

export type SearchFieldProps = {
  search: string;
  setSearch: (value: string) => void;
};

export default function SearchField({ search, setSearch }: SearchFieldProps) {
  return (
    <TextField
      placeholder="Cerca..."
      size="small"
      sx={{ flexGrow: 1 }}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <>
            <InputAdornment position="start" sx={{ pointerEvents: "none" }}>
              <SearchIcon
                style={{
                  fontSize: 20,
                }}
                sx={{
                  color: "text.disabled",
                }}
              />
            </InputAdornment>
          </>
        ),

        endAdornment: search ? (
          <>
            <InputAdornment position="end">
              <IconButton
                size="small"
                sx={{
                  color: "text.disabled",
                }}
                onClick={() => {
                  setSearch("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          </>
        ) : null,
      }}
    />
  );
}
