import { CALL_API } from "../middleware/api";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";

export const signup = (params) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [USER_SIGNUP_REQUEST, USER_SIGNUP_SUCCESS, USER_SIGNUP_FAILURE],
      endpoint: "/api/signup",
      method: "POST",
      body: params,
    },
  });
};
