import { EDistribuzioneEnergiaMensile } from "energix-types/src/edistribuzione";
import EDistribuzioneProduzioneDiEnergiaTable, {
  getTotalEDistribuzioneData,
  groupEDistribuzioneData,
} from "src/components/features/green/EDistribuzioneProduzioneDiEnergiaTable";
import { useMemo } from "react";

export type EDistribuzioneProduzioneDiEnergiaTableCardProps = {
  data: EDistribuzioneEnergiaMensile[];
};

export default function EDistribuzioneProduzioneDiEnergiaTableCard({
  data: rawData,
}: EDistribuzioneProduzioneDiEnergiaTableCardProps) {
  const preparedData = useMemo(
    () => groupEDistribuzioneData(rawData),
    [rawData]
  );
  const totale = useMemo(() => getTotalEDistribuzioneData(rawData), [rawData]);
  return (
    <EDistribuzioneProduzioneDiEnergiaTable
      data={preparedData}
      totale={totale}
    />
  );
}
