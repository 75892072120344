import { Icon } from "@mui/material";
import "material-symbols/rounded.css";
import { IconProps } from "@mui/material/Icon/Icon";
import "./fill-icon.css";
import { FileType } from "../../constants/fileTypes";
// import UploadExcel from "../../../imgs/UploadExcel.png";
// import DownloadExcel from "../../../imgs/DownloadExcel.png";
import DatiIdentificativiSottoscrittoreIconPng from "../../../imgs/DatiIdentificativiSottoscrittore.png";
import AttivitaPreliminariIconPng from "../../../imgs/AttivitaPreliminari.png";
import UtilizzoDelPortaleIconPng from "../../../imgs/UtilizzoDelPortale.png";

// import UploadFileSVG from "../../../imgs/UploadFileSVG.svg";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
//TODO: non sono riuscito a farlo funzionare in storybook e nel progetto nello stesso momento... assurdo...
// quindi ho creato file ts che esportano una string
// import UploadFileSVG from "!!raw-loader!../../../imgs/UploadFileSVG.svg.raw";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
// import DownloadFileSVG from "!!raw-loader!../../../imgs/DownloadFileSVG.svg.raw";
import UploadFileSVG from "../../../imgs/UploadFileSVG.svg.raw";
import DownloadFileSVG from "../../../imgs/DownloadFileSVG.svg.raw";

// https://fonts.google.com/icons

const MaterialSymbolRoundedIcon = ({
  name,
  fill = false,
  ...iconProps
}: { name: string; fill?: boolean } & IconProps) => (
  <Icon
    baseClassName="material-symbols-rounded"
    className={fill ? "fill-icon" : undefined}
    {...iconProps}
  >
    {name}
  </Icon>
);

const makeIcon = (name: string) => {
  return (props: IconProps & { fill?: boolean }) => (
    <MaterialSymbolRoundedIcon name={name} {...props} />
  );
};

export const HomeIcon = makeIcon("home");
export const AziendaIcon = makeIcon("apartment");
export const DichiarazioniAd1Icon = makeIcon("power");
export const ScadenzeIcon = makeIcon("speed");
export const NotificheIcon = makeIcon("mark_email_unread");
export const HistoryIcon = makeIcon("history");
export const GestioneNotificheIcon = makeIcon("notifications_active");
export const ServiziIcon = makeIcon("manage_accounts");
export const PasswordEFirmaIcon = makeIcon("key");
export const AddIcon = makeIcon("add_circle");
export const CloseIcon = makeIcon("close");
export const HelpdeskIcon = makeIcon("help");
export const GuideETutorialIcon = makeIcon("desktop_windows");
export const FaqIcon = makeIcon("quiz");
export const AssistenzaIcon = makeIcon("contact_support");
export const AccountIcon = makeIcon("person");
export const RightArrowIcon = makeIcon("navigate_next");
export const TrendingUpIcon = makeIcon("trending_up");
export const TrendingDownIcon = makeIcon("trending_down");
export const SearchIcon = makeIcon("search");
export const InfoIcon = makeIcon("info");
export const ErrorIcon = makeIcon("error");
export const WarningIcon = makeIcon("warning");
export const SuccessIcon = makeIcon("check_circle");
export const UploadIcon = makeIcon("file_upload");
export const DownloadIcon = makeIcon("download");
export const InvioIcon = makeIcon("outgoing_mail");
export const FirmaIcon = makeIcon("drive_file_rename_outline");
export const PartnerIcon = makeIcon("handshake");
export const ExpandLessIcon = makeIcon("expand_less");
export const ExpandMoreIcon = makeIcon("expand_more");
export const SortUpIcon = makeIcon("arrow_drop_up");
export const SortDownIcon = makeIcon("arrow_drop_down");
export const CodiceDittaIcon = makeIcon("qr_code_scanner");
export const SituazioneIcon = makeIcon("display_settings");
export const DenominazioneIcon = makeIcon("sort_by_alpha");
export const ComuneIcon = makeIcon("location_city");
export const ProvinciaIcon = makeIcon("meeting_room");
export const IndirizzoIcon = makeIcon("pin_drop");
export const DeleteIcon = makeIcon("delete");
export const EditIcon = makeIcon("border_color");
export const TipologiaIcon = makeIcon("plagiarism");
export const PotenzaIcon = makeIcon("bolt");
export const FileIcon = makeIcon("draft");
export const OpenIcon = makeIcon("open_in_new");
export const ClearIcon = makeIcon("cancel");
export const MoveUpIcon = makeIcon("arrow_upward");
export const MoveDownIcon = makeIcon("arrow_downward");
export const DocumentIcon = makeIcon("description");
export const PrintIcon = makeIcon("print");
export const BadgeIcon = makeIcon("badge");
export const AnteprimaIcon = makeIcon("visibility");
export const AttivitaPreliminariIcon = AttivitaPreliminariIconPng;
export const UtilizzoDelPortaleIcon = UtilizzoDelPortaleIconPng;
export const ScadenzaIcon = makeIcon("notifications_active");
export const CopyIcon = makeIcon("content_copy");
export const DropDownIcon = makeIcon("arrow_drop_down");
export const SettingsIcon = makeIcon("settings");
export const AdminIcon = makeIcon("admin_panel_settings");
export const TokenIcon = makeIcon("token");
export const RegistriLetturIcon = makeIcon("menu_book");
export const RinnovoAutomaticoIcon = makeIcon("replay");
export const PriceChangeIcon = makeIcon("price_change");
export const GestisciPagamentiIcon = makeIcon("account_balance_wallet");
export const GiftIcon = makeIcon("redeem");
export const CartaDiCreditoIcon = makeIcon("credit_card");
export const BonificoIcon = makeIcon("account_balance");
export const ArrowLeftIcon = makeIcon("arrow_left_alt");
export const UltimoAggiornamentoIcon = makeIcon("info");
export const TrasferisciImpiantoIcon = makeIcon("move_down");
export const DropdownIcon = makeIcon("arrow_drop_down");
export const DoneIcon = makeIcon("done");
export const MonitoraggioIcon = makeIcon("legend_toggle");
export const UnlockIcon = makeIcon("lock_open");

// required by theme
export const StarIcon = makeIcon("star");
export const ExpandIcon = makeIcon("add_box");
export const CollapseIcon = makeIcon("indeterminate_check_box");
export const ClosedSquaredIcon = makeIcon("disabled_by_default");
export const LogoutIcon = makeIcon("logout");
export const DatiIdentificativiSottoscrittoreIcon =
  DatiIdentificativiSottoscrittoreIconPng;

function _createSVG(baseImgSvg: string, text: string, fontSize: number) {
  const svgTxt = baseImgSvg
    .replace(/___INSERT_FILE_TYPE___/, text)
    .replace(/___INSERT_FONT_SIZE___/, "" + fontSize);
  return "data:image/svg+xml;base64," + window.btoa(svgTxt);
}

function createUploadSvg(text: string, fontSize = 27.2) {
  return _createSVG(UploadFileSVG, text, fontSize);
}

function createDownloadSvg(text: string, fontSize = 10.2) {
  return _createSVG(DownloadFileSVG, text, fontSize);
}

export const fileTypeIcon: {
  [k in FileType]: { upload: string; download: string };
} = {
  excel: {
    upload: createUploadSvg("XLSX"),
    download: createDownloadSvg("XLSX"),
  },
  xml: {
    upload: createUploadSvg("XML"),
    download: createDownloadSvg("XML"),
  },
  "c01/xml": {
    upload: createUploadSvg("C01/XML", 22),
    download: createDownloadSvg("C01/XML", 8),
  },
  png: {
    upload: createUploadSvg("PNG", 22),
    download: createDownloadSvg("PNG", 8),
  },
};

export const uploadGenericFileIcon = createUploadSvg("");
