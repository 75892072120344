import { Alert, Checkbox, Stack, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { Azienda } from "energix-types/src/Azienda";
import { useMemo, useState } from "react";
import BaseTable from "src/components/BaseTable";
import { WarningIcon } from "src/components/elements/AppIcons";
import SearchField from "src/components/elements/SearchField";
import {
  EdistribuzioneCredenzialiDto,
  GSECredenzialiDto,
} from "src/orval/models";

type AziendaRow = {
  id: number;
  richiedenteNome: string;
  credenzialiId: number | null;
  credenzialiDiverse: boolean;
};

const columnHelper = createColumnHelper<AziendaRow>();

export function AziendePickerPerCredenziali({
  credenziali,
  aziende,
  value,
  onChange,
  credenzialiKey,
}: {
  credenziali: EdistribuzioneCredenzialiDto | GSECredenzialiDto;
  aziende: Azienda[];
  value: number[];
  onChange: (picked: number[]) => void;
  credenzialiKey: "credenzialiEdistribuzioneId" | "credenzialiGSEId";
}) {
  const [search, setSearch] = useState("");

  const rows = useMemo(() => {
    return aziende.map((a) => {
      const credenzialiDiverse =
        typeof a[credenzialiKey] === "number" &&
        a[credenzialiKey] !== credenziali.id;

      return {
        id: a.id,
        richiedenteNome: a.richiedenteNome,
        credenzialiId: a[credenzialiKey],
        credenzialiDiverse: credenzialiDiverse,
      } as AziendaRow;
    });
  }, [aziende, credenziali?.id, credenzialiKey]);

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "_selected",
        header: "",
        size: 10,
        cell: ({
          row: {
            original: { id, credenzialiDiverse },
          },
        }) => {
          return (
            <Checkbox
              sx={{ padding: 0 }}
              checked={value.includes(id)}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...value, id]);
                } else {
                  onChange(value.filter((v) => v !== id));
                }
              }}
            />
          );
        },
      }),
      columnHelper.accessor("richiedenteNome", {
        header: "Azienda",
        cell: ({ row: { original } }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              {original.credenzialiDiverse && value.includes(original.id) && (
                <WarningIcon color="warning" sx={{ pr: 1 }} />
              )}
              <Typography
                color={original.credenzialiDiverse ? "gray" : undefined}
                fontStyle={original.credenzialiDiverse ? "italic" : undefined}
              >
                {original.richiedenteNome}
              </Typography>
            </Stack>
          );
        },
      }),
    ];
  }, [value, onChange]);

  const warningCount = useMemo(() => {
    return rows.filter((r) => r.credenzialiDiverse && value.includes(r.id))
      .length;
  }, [rows, value]);

  return (
    <Stack direction="column" sx={{ mt: 1 }}>
      {warningCount > 0 && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          {warningCount === 1
            ? "Un azienda ha "
            : warningCount + " aziende hanno "}
          già delle credenziali associate. Premendo pulsante "Salva" le
          credenziali precedentemente associate a{" "}
          {warningCount === 1 ? "questa azienda" : "queste aziende"} verranno
          dissociate.
        </Alert>
      )}

      <Stack direction="row" alignItems="center">
        <Typography sx={{ mr: 1 }}>Aziende</Typography>
        <SearchField search={search} setSearch={setSearch} />
      </Stack>
      <BaseTable
        columns={columns}
        data={rows}
        globalFilter={search}
        noRowsText="Nessuna azienda"
      />
    </Stack>
  );
}
