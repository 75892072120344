import React, { useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";
import TabsCardHeader from "src/components/elements/TabsCardHeader";

export function useFilterByPod<T extends { pod: string | null }>(
  enableFilter: boolean,
  data: T[]
) {
  const pods = useMemo(() => uniq(data.map((d) => d.pod)).sort(), [data]);
  const tabs: { label: string; filter?: (c: T) => boolean }[] = useMemo(
    () => [
      { label: "Tutti" },
      ...pods
        .filter((pod) => !!pod)
        .map((pod) => ({
          label: pod!,
          filter: (c: T) => c.pod === pod,
        })),
    ],
    [pods]
  );
  const [selectedPodIndex, setSelectedPodIndex] = useState(0);
  useEffect(() => {
    setSelectedPodIndex(0);
  }, [tabs]);

  const canFilter = enableFilter && pods.length > 1;
  const currentFilter = tabs[selectedPodIndex];

  const filteredData = useMemo(() => {
    if (!canFilter || !currentFilter?.filter) {
      return data;
    }
    return data.filter((d) => currentFilter.filter!(d));
  }, [canFilter, currentFilter, data]);

  return {
    TabsComponent: canFilter ? (
      <TabsCardHeader
        tabs={tabs}
        tab={selectedPodIndex}
        onChange={setSelectedPodIndex}
      />
    ) : null,
    filteredData,
  };
}
