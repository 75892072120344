import React, { ReactNode } from "react";
import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from "@mui/material";
import {
  CellContext,
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  SortingFn,
  sortingFns,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  compareItems,
  RankingInfo,
  rankings,
  rankItem,
} from "@tanstack/match-sorter-utils";
import { SortDownIcon, SortUpIcon } from "./elements/AppIcons";

export type BaseTableTableProps<RowData> = {
  className?: string;
  columns: ColumnDef<RowData, any>[];
  data: RowData[];
  paginated?: boolean;
  sortable?: boolean;
  globalFilter?: string | null;
  fixedColumnWidth?: boolean;
  initialSort?: SortingState;
  // setGlobalFilter?: (newValue: string) => void;
  // columnFilters?: ColumnFiltersState;
  // setColumnFilters?: (
  //   newColumnFilters:
  //     | ColumnFiltersState
  //     | ((old: ColumnFiltersState) => ColumnFiltersState)
  // ) => void;
  autoResetPageIndex?: boolean;
  sx?: SxProps<Theme> | undefined;
  TableRowComponent?: React.ComponentType<{
    row: RowData;
    children: ReactNode;
  }>;
  mt?: number;
  noRowsText?: string;
  defaultPageSize?: 10 | 25 | 100;
};

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    colSpan?: (row: CellContext<any, TValue>) => number;
    columnPostfix?: ReactNode;
    verticalAlign?: string;
  }
}

const BaseTable = <RowData,>({
  className,
  sx,
  data,
  columns,
  paginated = true,
  sortable = true,
  globalFilter,
  fixedColumnWidth = false,
  initialSort = [],
  autoResetPageIndex = true,
  TableRowComponent = TableRow,
  mt = 3,
  noRowsText = "Nessuna riga",
  defaultPageSize = 25,
}: // setGlobalFilter,
// columnFilters,
// setColumnFilters,
BaseTableTableProps<RowData>) => {
  const [sorting, setSorting] = React.useState<SortingState>(initialSort);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: sortable ? getSortedRowModel() : undefined,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: paginated ? getPaginationRowModel() : undefined,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      // columnFilters,
      sorting,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: defaultPageSize,
      },
    },
    autoResetPageIndex,
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  });

  return (
    <Box
      className={className}
      sx={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
    >
      <TableContainer
        sx={{
          mt,
          minWidth: 0,
          width: "auto",
        }}
      >
        <Table
          style={{
            width: fixedColumnWidth ? table.getCenterTotalSize() : undefined,
          }}
          sx={sx}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: fixedColumnWidth ? header.getSize() : undefined,
                    }}
                    onClick={
                      sortable
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {sortable
                          ? {
                              asc: <SortUpIcon />,
                              desc: <SortDownIcon />,
                            }[header.column.getIsSorted() as string] ?? null
                          : null}
                        {header.column.columnDef.meta?.columnPostfix}
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRowComponent key={row.id} row={row.original}>
                {row.getVisibleCells().map((cell) => {
                  const colSpan =
                    cell.column.columnDef.meta?.colSpan?.(cell.getContext()) ??
                    1;
                  return colSpan <= 0 ? null : (
                    <TableCell
                      colSpan={colSpan}
                      key={cell.id}
                      style={{
                        verticalAlign:
                          cell.column.columnDef.meta?.verticalAlign,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRowComponent>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <TableRow style={{ pointerEvents: "none" }}>
                <TableCell colSpan={table.getVisibleFlatColumns().length}>
                  {noRowsText}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginated && (
        <TablePagination
          component="div"
          page={table.getState().pagination.pageIndex}
          count={table.getPrePaginationRowModel().rows.length}
          onPageChange={(event, page) => {
            console.log("onPageChange", event, page);
            table.setPageIndex(page);
          }}
          rowsPerPage={table.getState().pagination.pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
        />
      )}
    </Box>
  );
};

export default BaseTable;

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: rankings.CONTAINS,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export function createSortingFunction<
  Field extends string,
  TData extends { [k in Field]: Date | string | null }
>(field: Field): SortingFn<TData> {
  return ({ original: originalA }, { original: originalB }) => {
    const dataA = originalA[field];
    const dataB = originalB[field];
    if (dataA === null || dataB === null) {
      return 0;
    } else if (dataA < dataB) {
      return -1;
    } else if (dataA > dataB) {
      return 1;
    }
    return 0;
  };
}
