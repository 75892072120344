import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import AppDialogActions from "../elements/AppDialogActions";
import AppDialogTitle from "../elements/AppDialogTitle";

type ServizioNonAbilitatoDialogProps = {
  open: boolean;
  title: string;
  text: string;
  onClose: () => void;
  adminLoggedAsUserPrefix?: string;
};

function ServizioNonAbilitatoDialog({
  text,
  title,
  open,
  onClose,
  adminLoggedAsUserPrefix,
}: ServizioNonAbilitatoDialogProps) {
  return (
    <Dialog open={open}>
      <AppDialogTitle>{title}</AppDialogTitle>
      <DialogContent>
        <Typography>
          {text}
          <br />
          Puoi acquistare il servizio dalla pagina Servizi
        </Typography>
      </DialogContent>
      <AppDialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button
          component={Link}
          to={`${adminLoggedAsUserPrefix}/servizi`}
          variant="contained"
        >
          Servizi
        </Button>
      </AppDialogActions>
    </Dialog>
  );
}

export function useServizioNonAbilitatoDialog(
  adminLoggedAsUserPrefix?: string
) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  return {
    dialog: (
      <ServizioNonAbilitatoDialog
        open={open}
        adminLoggedAsUserPrefix={adminLoggedAsUserPrefix}
        title={title}
        text={text}
        onClose={useCallback(() => {
          setOpen(false);
        }, [])}
      />
    ),
    openServizioNonAbilitatoDialog: (title: string, text: string) => {
      setTitle(title);
      setText(text);
      setOpen(true);
    },
  };
}
