import * as yup from "yup";
/* eslint-disable no-unused-vars */
// ex Installation
import { ImpiantoType } from "./DefinizioneImpianto";
import { UploadedFile } from "./UploadedFile";
import {
  GSEFotovoltaicoCorrispettivi,
  GSERidCorrispettivi,
  GSESspCorrispettivi,
} from "./GSE";
import { EDistribuzioneEnergiaMensile } from "./edistribuzione";
import { FilesServizio } from "./FileServizio";
import { AlertEdistribuzione } from "./AlertEdistribuzione";

export interface Impianto {
  id: number;
  type: ImpiantoType;
  name: null | string;
  installationCode: string | null;
  powerWatts: number | null;
  firstYear: number | null;
  tipologiaDiConsumo: TipologiaDiConsumo | null;
  nomeSoggetto: string | null;
  codiceCatastaleSoggetto: string | null;
  indirizzoSoggetto: string | null;
  codiceCatastaleUfficioAmministrativo: string | null;
  indirizzoUfficioAmministrativo: string | null;
  codiceCatastaleImpianto: string | null;
  indirizzoImpianto: string | null;
  indirizziVerificati: boolean;
  installationInfo: InstallationInfo;
  notificationsEnabled: StatoNotificheImpianto;
  // service_id_by_year: { [key: string]: number };
  installationState: StatoImpianto | null;
  servizioSPIAbilitato: boolean;
  ultimaTaraturaSPI: Date | string | null;
  frequenzaTaraturaSPI: number | null;
  filesServizioSPI: FilesServizio[] | null;
  note: null | string;
  created_at: Date | string;
  updated_at: Date | string;
  user_id: number;
  license_id: number;
  // service_id: number;
  // last_servizio_spi_acquistato_id: number | null;
  files?: UploadedFile[];
  clientWebsiteIdentifier?: null | string;
  sintesiEDistribuzione?: SintesiEDistribuzione;
  isImpiantoGreen: boolean;
  isImpiantoGreenContatoriManuali: boolean;
  isImpiantoAd1: boolean;
  sogliaEdistribuzione: number | null;

  servizioScadenzaRegistroAbilitato: boolean;
  servizioScadenzaRegistroAnno: number | null;
  servizioScadenzaRegistroPeriodicita: number | null;
  servizioScadenzaRegistroTipo: "mensile" | "giornaliero" | null;
  servizioScadenzaRegistroContatori:
    | {
        matricola: string;
        tipo: string | null;
        k: number | null;
      }[]
    | null;
  servizioScadenzaRegistroPec: string | null;
}

export type StatoNotificheImpianto = {
  [idNotifica: string]: boolean | undefined;
};

export interface InstallationInfo {}

export enum StatoImpiantoEnum {
  Attivo = "Attivo",
  Inattivo = "Inattivo",
  Sospeso = "Sospeso",
  Archiviato = "Archiviato",
}

export type StatoImpianto = keyof typeof StatoImpiantoEnum;

export enum TipologiaDiConsumoEnum {
  J1 = "J1",
  J2 = "J2",
  J3 = "J3",
  J4 = "J4",
}

export type TipologiaDiConsumo = keyof typeof TipologiaDiConsumoEnum;

export type ImpiantoGreenDettagliGseType = "rid" | "ssp" | "conto";

export type ImpiantoGreen = Impianto & {
  // pagina di dettaglio
  contoEnergiaList?: GSEFotovoltaicoCorrispettivi[];
  ridList?: GSERidCorrispettivi[];
  sspList?: GSESspCorrispettivi[];
  eDistribuzioneEnergiaMensileList?: EDistribuzioneEnergiaMensile[];

  reportEdistribuzione: ReportEdistribuzione[];
  reportGSE: ReportGSE[];
  ultimoAlertEdistribuzione?: AlertEdistribuzione | null;
};

export type ReportEdistribuzione = {
  id: number;
  anno: number;
  mese: number;
  fileReportId: number;
};

export type ReportGSE = {
  id: number;
  anno: number;
  mese: number;
  fileReportId: number;
};

export type SintesiEDistribuzione = {
  ultimoAnnoScaricatoProduzioneEDistribuzione?: number;
  ultimoMeseScaricatoProduzioneEDistribuzione?: number; // 1-12

  produzioneWattsUltimoMese?: number;
  produzioneWattsUltimi12Mesi?: number;
  produzioneWattsUltimoMeseAnnoPrecedente?: number;
  produzioneWattsUltimi12MesiAnnoPrecedente?: number;
};

export const createOrUpdateImpiantoGreenSchema = yup
  .object({
    name: yup.string().required("Nome obbligatorio"),
    indirizzoImpianto: yup.string().nullable(),
    powerWatts: yup.number().nullable(),
  })
  .required();

export function filterImpiantoRegistroLettureInScadenza(
  impianti: Impianto[],
  annoCorrente: number
) {
  return impianti.filter((impianto) => {
    if (
      !impianto.servizioScadenzaRegistroAbilitato ||
      !impianto.servizioScadenzaRegistroAnno ||
      !impianto.servizioScadenzaRegistroPeriodicita
    ) {
      return false;
    }

    const annoScadenza =
      impianto.servizioScadenzaRegistroAnno +
      (impianto.servizioScadenzaRegistroPeriodicita - 1);

    return annoCorrente === annoScadenza;
  });
}
