import { datiByCodiceCatastale, datiByComune } from "./datiIstat";
import { map } from "lodash";

/*
Officina di produzione da fonti rinnovabili

*/

export const labelTipiContatori = {
  produzione: "A - Produzione",
  promiscuo: "B - Uso promiscuo",
  consumiPropriEsenti: "C - Consumi propri esenti da accisa",
  consumiPropriAssoggettati: "E - Consumi propri assoggettati ad accisa",
  ceduta: "G - Energia elettrica ceduta",
  ricevuta: "H - Energia elettrica ricevuta",
  consumiPropriNonSottoposti: "X - Consumi propri non sottoposti",
};

export const shortLabelTipoContatori = {
  produzione: "A",
  promiscuo: "B",
  consumiPropriEsenti: "C",
  consumiPropriAssoggettati: "E",
  ceduta: "G",
  ricevuta: "H",
  consumiPropriNonSottoposti: "X",
};

const tipiDiContatoriOnlyProd = [
  {
    value: "produzione",
    label: labelTipiContatori.produzione + " + " + labelTipiContatori.ceduta,
  },
];
export const tipiDiContatoriWithCeduta = [
  { value: "produzione", label: labelTipiContatori.produzione },
  { value: "ceduta", label: labelTipiContatori.ceduta },
];
const tipiDiContatoriWithCedutaEContatoriC_H = [
  { value: "produzione", label: labelTipiContatori.produzione },
  { value: "ceduta", label: labelTipiContatori.ceduta },
  {
    value: "consumiPropriEsenti",
    label: labelTipiContatori.consumiPropriEsenti,
  },
  { value: "ricevuta", label: labelTipiContatori.ricevuta },
];
const tipiDiContatoriAll = [
  { value: "produzione", label: labelTipiContatori.produzione },
  { value: "promiscuo", label: labelTipiContatori.promiscuo },
  {
    value: "consumiPropriEsenti",
    label: labelTipiContatori.consumiPropriEsenti,
  },
  {
    value: "consumiPropriAssoggettati",
    label: labelTipiContatori.consumiPropriAssoggettati,
  },
  { value: "ceduta", label: labelTipiContatori.ceduta },
  { value: "ricevuta", label: labelTipiContatori.ricevuta },
  {
    value: "consumiPropriNonSottoposti",
    label: labelTipiContatori.consumiPropriNonSottoposti,
  },
];

const canChangeTypeToAll = {
  CESSIONE_PARZIALE_NON_COMMERCIALE: true,
  CESSIONE_PARZIALE_COMMERCIALE: true,
  CESSIONE_TOTALE: true,
  USO_PROPRIO: true,
};

const installations = [
  {
    type: "CESSIONE_PARZIALE_NON_COMMERCIALE",
    name: "Cessione Parziale Esente da Accisa",
    haCeduta: true,
    letturaAnnuale: false,
    hasPotenzaNominale: true,
    docsUltimaDichiarazione: true,
    docsCreditiAccise: false,
    docsRelazioneTecnica: false,
    helpContatore: true,
    chiedeDatiAcciseAnnoPrecedente: false,
    chiediNumeroDiContatori: true,
    tipologiaDiCessione: (contatoreType) => contatoreType === "ceduta",
    tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
    // per generazione file
    tipologiaDichiarante: "L",
    tipoImpianto: "01",
    tipiDiContatori: tipiDiContatoriWithCedutaEContatoriC_H,
    canChangeTypeTo: canChangeTypeToAll,
    // canChangeTypeTo: {
    //   'CESSIONE_PARZIALE_COMMERCIALE': true,
    //   'USO_PROPRIO': true
    // }
  },
  // {
  //   type: "CESSIONE_PARZIALE_COMMERCIALE",
  //   name: "Cessione Parziale Assoggettata ad Accisa",
  //   haCeduta: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: false,
  //   helpContatore: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   chiediNumeroDiContatori: true,
  //   tipologiaDiCessione: (contatoreType) => contatoreType === "ceduta",
  //   tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
  //   // per generazione file
  //   tipologiaDichiarante: "L",
  //   tipoImpianto: "02",
  //   tipiDiContatori: tipiDiContatoriWithCeduta,
  //   canChangeTypeTo: canChangeTypeToAll,
  //   // canChangeTypeTo: {
  //   //   'CESSIONE_PARZIALE_NON_COMMERCIALE': true,
  //   //   'USO_PROPRIO': true
  //   // }
  //   chiediQuadroEF: true,
  // },
  {
    type: "CESSIONE_TOTALE",
    name: "Cessione Totale",
    haCeduta: false,
    letturaAnnuale: true,
    hasPotenzaNominale: true,
    docsUltimaDichiarazione: true,
    docsCreditiAccise: false,
    docsRelazioneTecnica: false,
    helpContatore: true,
    chiedeDatiAcciseAnnoPrecedente: false,
    chiediNumeroDiContatori: true,
    tipologiaDiCessione: (contatoreType, reportInfo) => {
      if (reportInfo && reportInfo.haContatoriDiversi) {
        return contatoreType === "ceduta";
      }
      return true; // il contatore di produzione è anche di cessione
    },
    tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
    // per generazione file
    tipologiaDichiarante: "N",
    tipoImpianto: "00",
    tipiDiContatori: tipiDiContatoriOnlyProd,
    canChangeTypeTo: canChangeTypeToAll,
    // canChangeTypeTo: {
    //   'CESSIONE_PARZIALE_NON_COMMERCIALE': true,
    //   'CESSIONE_PARZIALE_COMMERCIALE': true,
    //   'USO_PROPRIO': true
    // }
  },
  {
    type: "USO_PROPRIO",
    name: "Altre officine",
    necessitaAlgoritmo: (reportInfo) => {
      if (reportInfo.metodoDiCompilazioneSemplificato) {
        return false;
      }
      return true;
    },
    letturaAnnuale: false,
    hasPotenzaNominale: false,
    docsUltimaDichiarazione: true,
    docsCreditiAccise: true,
    docsRelazioneTecnica: true,
    chiedeDatiAcciseAnnoPrecedente: true,
    tipologiaDiCessione: (contatoreType) => contatoreType === "ceduta",
    tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
    tipologiaDiConsumo: true,
    tipologiaDichiarante: "L",
    tipoImpianto: "02",
    tipiDiContatori: tipiDiContatoriAll,
    canChangeTypeTo: canChangeTypeToAll,
    // canChangeTypeTo: {}
    chiediQuadroEF: true,
  },
  {
    type: "IMPIANTO_DI_VENDITA",
    hidden: "venditore",
    name: "Impianto di vendita",
    //TODO: per ora tanto è solo con caricamento dell'xml per l'invio
    // necessitaAlgoritmo: true,
    // letturaAnnuale: false,
    // hasPotenzaNominale: false,
    // docsUltimaDichiarazione: true,
    // docsCreditiAccise: true,
    // docsRelazioneTecnica: true,
    // chiedeDatiAcciseAnnoPrecedente: true,
    // tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
    // tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
    // tipologiaDiConsumo: true,
    // tipologiaDichiarante: 'L',
    // tipoImpianto: '02',
    // tipiDiContatori: tipiDiContatoriAll,
    canChangeTypeTo: {},
  },
  {
    type: "NON_CONFIGURATO",
    name: "Non ancora configurato",
    //haCeduta: true,
    //letturaAnnuale: false,
    hasPotenzaNominale: false,
    docsUltimaDichiarazione: false,
    docsCreditiAccise: false,
    docsRelazioneTecnica: false,
    helpContatore: false,
    chiedeDatiAcciseAnnoPrecedente: false,
    chiediNumeroDiContatori: false,
    //tipologiaDichiarante: "L",
    //tipoImpianto: "01",
    canChangeTypeTo: canChangeTypeToAll,
  },
  // }, {
  //   type: 'CESSIONE_PARZIALE_NON_COMMERCIALE_E_USO_PROPRIO',
  //   name: 'Cessione Parziale Esente da Accisa + Uso proprio',
  //   necessitaAlgoritmo: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
  //   tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
  //   tipologiaDiConsumo: true,
  //   tipologiaDichiarante: 'L',
  //   tipoImpianto: '02',
  //   tipiDiContatori: tipiDiContatoriAll
  // }, {
  //   type: 'CESSIONE_PARZIALE_COMMERCIALE_E_USO_PROPRIO',
  //   name: 'Cessione Parziale Assoggettata ad Accisa + Uso proprio',
  //   necessitaAlgoritmo: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
  //   tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
  //   tipologiaDiConsumo: true,
  //   tipologiaDichiarante: 'L',
  //   tipoImpianto: '02',
  //   tipiDiContatori: tipiDiContatoriAll
  // }, {
  //   type: 'CESSIONE_TOTALE_E_USO_PROPRIO',
  //   name: 'Cessione Totale + Uso proprio',
  //   necessitaAlgoritmo: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
  //   tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
  //   tipologiaDiConsumo: true,
  //   tipologiaDichiarante: 'L',
  //   tipoImpianto: '02',
  //   tipiDiContatori: tipiDiContatoriAll
  // }
];

export default installations;

const _installationsByType = {};
installations.forEach((installation) => {
  _installationsByType[installation.type] = installation;
});

export const installationsByType = _installationsByType;

export const tipologieDiCessione = {
  A: "cessione a consorziati/consociati",
  B: "vettoriamento",
  C: "cessione ad altra officina elettrica",
  D: "distribuzione per conto terzi",
  E: "cessione UE",
  F: "cessione extra UE",
};

export const tipologieDiRicezione = {
  G: "ricezione da consorziati/consociati",
  H: "vettoriamento",
  I: "ricezione da altra officina elettrica",
  J: "ricezione UE",
  K: "ricezione extra UE",
};

export const tipologieDiConsumo = {
  J1: "Energia elettrica utilizzata principalmente per la riduzione chimica e nei processi elettrolitici e metallurgici",
  J2: "Energia elettrica impiegata nei processi mineralogici",
  J3: "Energia elettrica impiegata per la realizzazione di prodotti sul cui costo finale, calcolato in media per unità, incida per oltre il 50 per cento",
  J4: "Fornitura a clienti UE",
  J5: "Fornitura a clienti extra UE",
};

export const tipologieFornitura = {
  L: `fornitura a consorziati/consociati `,
  M: `fornitura al consumo `,
  N: `fornitura UE`,
  O: `fornitura extra UE`,
  P: `venduta ad altri operatori del mercato elettrico`,
  Q: `fornitura a ditte acquirenti per uso proprio (soggetti obbligati ex art. 53 comma 1 lettere "c" e "c-bis" e comma 2 D. Lgs. 504/95`,
};

export const tipologieDiCessioneSelect = map(tipologieDiCessione, (v, k) => {
  return { label: k + ") " + v, value: k };
});
export const tipologieDiRicezioneSelect = map(tipologieDiRicezione, (v, k) => {
  return { label: k + ") " + v, value: k };
});
export const tipologieDiConsumoSelect = map(tipologieDiConsumo, (v, k) => {
  return { label: k + ") " + v, value: k };
});
export const tipologieFornituraSelect = map(tipologieFornitura, (v, k) => {
  return { label: k + ") " + v, value: k };
});

export const tipologieDiProduzioneSelect = [
  { label: "Fonti rinnovabili", value: "" },
  { label: "Fonti non rinnovabili", value: "non-rinnovabili" },
];

export function getIndirizzi(installation, user) {
  const obj = {
    nomeSoggetto: installation.nomeSoggetto || user.ragioneSociale,
    codiceCatastaleSoggetto: installation.codiceCatastaleSoggetto,
    indirizzoSoggetto: installation.indirizzoSoggetto,
    comuneSoggetto: null,
    provinciaSoggetto: null,

    codiceCatastaleUfficioAmministrativo:
      installation.codiceCatastaleUfficioAmministrativo,
    indirizzoUfficioAmministrativo: installation.indirizzoUfficioAmministrativo,
    comuneUfficioAmministrativo: null,
    provinciaUfficioAmministrativo: null,

    codiceCatastaleImpianto: installation.codiceCatastaleImpianto,
    indirizzoImpianto: installation.indirizzoImpianto,
    comuneImpianto: null,
    provinciaImpianto: null,
  };

  if (obj.codiceCatastaleSoggetto) {
    const d = datiByCodiceCatastale[obj.codiceCatastaleSoggetto];
    obj.comuneSoggetto = d && d.comune;
    obj.provinciaSoggetto = d && d.provincia;
  } else {
    const d = datiByComune[user.comune];
    obj.codiceCatastaleSoggetto = d && d.codiceCatastale;
    obj.comuneSoggetto = d && d.comune;
    obj.provinciaSoggetto = d && d.provincia;
    obj.indirizzoSoggetto = user.indirizzo;
  }

  if (obj.codiceCatastaleImpianto) {
    const d = datiByCodiceCatastale[obj.codiceCatastaleImpianto];
    obj.comuneImpianto = d && d.comune;
    obj.provinciaImpianto = d && d.provincia;
  } else {
    const d = datiByComune[obj.comuneSoggetto] || datiByComune[user.comune];
    obj.codiceCatastaleImpianto = d && d.codiceCatastale;
    obj.comuneImpianto = d && d.comune;
    obj.provinciaImpianto = d && d.provincia;
  }

  if (obj.codiceCatastaleUfficioAmministrativo) {
    const d = datiByCodiceCatastale[obj.codiceCatastaleUfficioAmministrativo];
    obj.comuneUfficioAmministrativo = d && d.comune;
    obj.provinciaUfficioAmministrativo = d && d.provincia;
  } else {
    const d = datiByCodiceCatastale[obj.codiceCatastaleImpianto];
    obj.codiceCatastaleUfficioAmministrativo = d && d.codiceCatastale;
    obj.comuneUfficioAmministrativo = d && d.comune;
    obj.provinciaUfficioAmministrativo = d && d.provincia;
    obj.indirizzoUfficioAmministrativo = user.indirizzo;
  }

  return obj;
}

export function reverseIndirizzi(indirizzi, user) {
  const obj = {
    nomeSoggetto: indirizzi.nomeSoggetto,
    codiceCatastaleSoggetto: null,
    indirizzoSoggetto: indirizzi.indirizzoSoggetto,
    codiceCatastaleUfficioAmministrativo: null,
    indirizzoUfficioAmministrativo: indirizzi.indirizzoUfficioAmministrativo,
    codiceCatastaleImpianto: indirizzi.codiceCatastaleImpianto,
    indirizzoImpianto: indirizzi.indirizzoImpianto,
  };

  if (indirizzi.comuneSoggetto) {
    const d = datiByComune[indirizzi.comuneSoggetto];
    obj.codiceCatastaleSoggetto = d && d.codiceCatastale;
  } else {
    obj.codiceCatastaleSoggetto = indirizzi.codiceCatastaleSoggetto;
  }

  if (indirizzi.comuneImpianto) {
    const d = datiByComune[indirizzi.comuneImpianto];
    obj.codiceCatastaleImpianto = d && d.codiceCatastale;
  } else {
    obj.codiceCatastaleImpianto = indirizzi.codiceCatastaleImpianto;
  }

  if (indirizzi.comuneUfficioAmministrativo) {
    const d = datiByComune[indirizzi.comuneUfficioAmministrativo];
    obj.codiceCatastaleUfficioAmministrativo = d && d.codiceCatastale;
  } else {
    obj.codiceCatastaleUfficioAmministrativo =
      indirizzi.codiceCatastaleUfficioAmministrativo;
  }

  return obj;
}
