import { SxProps, Theme } from "@mui/material";
import { darken } from "@mui/material/styles";
import { ColorPair } from "./colorUtils";

export function generateSxProps(selectedColor: ColorPair): SxProps<Theme> {
  return {
    border: "none !important",
    backgroundColor: "#f4f6f8",
    color: "black",
    "&.Mui-selected": {
      backgroundColor: selectedColor.backgroundColor,
      color: selectedColor.color,
    },
    "&.Mui-selected:hover": {
      backgroundColor: darken(selectedColor.backgroundColor, 0.16),
      color: selectedColor.color,
    },
  };
}
