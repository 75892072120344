import { CALL_API } from "src/middleware/api";
import { ParsedData } from "energix-types/src/utils/edistribuzione.utils";

// types

export type GetLettureAziendaFunction = (
  aziendaId: number,
  impiantoId: number,
  anno: number
) => Promise<GetLettureAziendaResult>;

export type GetLettureAziendaResult =
  | {
      data: LettureType;
      response: any;
      error: null;
    }
  | { data: null; response: any; error: string };

export type LettureType = ParsedData;

export type GetLettureAziendaPaidFunction = (
  aziendaId: number,
  impiantoId: number,
  anno: number
) => Promise<GetLettureAziendaPaidResult>;

export type GetLettureAziendaPaidResult =
  | {
      data: boolean;
      response: any;
      error: null;
    }
  | { data: null; response: any; error: string };

// API call

export const getLettureAzienda_REQUEST = "getLettureAzienda_REQUEST";
export const getLettureAzienda_SUCCESS = "getLettureAzienda_SUCCESS";
export const getLettureAzienda_FAILURE = "getLettureAzienda_FAILURE";

export const getLettureAziendaAction =
  (aziendaId: number, impiantoId: number, anno: number) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          getLettureAzienda_REQUEST,
          getLettureAzienda_SUCCESS,
          getLettureAzienda_FAILURE,
        ],
        endpoint: `/api/azienda/${aziendaId}/impianto/${impiantoId}/letture/${anno}`,
        method: "GET",
      },
    });
  };

export const getLettureAziendaPaid_REQUEST = "getLettureAziendaPaid_REQUEST";
export const getLettureAziendaPaid_SUCCESS = "getLettureAziendaPaid_SUCCESS";
export const getLettureAziendaPaid_FAILURE = "getLettureAziendaPaid_FAILURE";

export const getLettureAziendaPaidAction =
  (aziendaId: number, impiantoId: number, anno: number) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          getLettureAziendaPaid_REQUEST,
          getLettureAziendaPaid_SUCCESS,
          getLettureAziendaPaid_FAILURE,
        ],
        endpoint: `/api/azienda/${aziendaId}/impianto/${impiantoId}/letture/${anno}/paid`,
        method: "GET",
      },
    });
  };
