import { DialogTitle, Theme } from "@mui/material";
import { ReactNode } from "react";

export type AppDialogTitleProps = { children: ReactNode };

const dialogTitleStyle = {
  padding: 0,
  paddingBottom: (theme: Theme) => theme.spacing(1),
  margin: (theme: Theme) => theme.spacing(2),
  borderBottom: (theme: Theme) => `2px solid ${theme.palette.grey[50016]}`,
};

export default function AppDialogTitle({ children }: AppDialogTitleProps) {
  return <DialogTitle sx={dialogTitleStyle}>{children}</DialogTitle>;
}
