import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  lighten,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Impianto } from "energix-types/src/Impianto";
import { Notifica, TargetNotifica } from "energix-types/src/Notifica";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { generateSxProps } from "../../common/toggleButtons";
import { colors, darkBackgroundColors } from "src/components/common/colorUtils";
import {
  getMergedNotifiche,
  isNotificaManuale,
} from "../../../getters/notifiche";
import { UpdateStatoNotificheImpiantoAction } from "src/actions/updateStatoNotificheImpianto";
import {
  isImpiantoAttivoForNotification,
  isNotificationEnabled,
} from "./getters";

export type ImpostazioniNotificheImpiantoCardProps = {
  impianto: Impianto;
  notificheAdmin: Notifica[];
  notifichePartner: Notifica[];
  targetNotifiche: { [target in TargetNotifica]: boolean };
  updateStatoNotificheImpianto: UpdateStatoNotificheImpiantoAction;
};

export default function ImpostazioniNotificheImpiantoCard({
  impianto,
  notificheAdmin,
  notifichePartner,
  targetNotifiche,
  updateStatoNotificheImpianto,
}: ImpostazioniNotificheImpiantoCardProps) {
  const notificheAttive = isImpiantoAttivoForNotification(impianto);

  const notifiche = getMergedNotifiche(notifichePartner, notificheAdmin);
  const notificheRelativeAImpianto = notifiche.filter((n) => {
    if (isNotificaManuale(n)) {
      return false;
    }
    if (n.tipoImpianto && n.tipoImpianto !== impianto.type) {
      return false;
    }
    return targetNotifiche[n.target];
  });

  const [saving, setSaving] = useState(false);

  async function saveNotificaState(
    idNotifica: number,
    state: boolean | undefined
  ) {
    setSaving(true);
    const success = await updateStatoNotificheImpianto(impianto.id, {
      ...impianto.notificationsEnabled,
      [idNotifica]: state,
    });
    setSaving(false);
    return success;
  }

  return (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6">Impostazioni notifiche</Typography>
        {notificheAttive ? (
          <table>
            <tbody>
              {notificheRelativeAImpianto.map((n) => (
                <ImpostazioneNotifica
                  key={n.id}
                  notifica={n}
                  statoNotificaImpianto={isNotificationEnabled(impianto, n)}
                  saveStatoNotificaImpianto={saveNotificaState}
                  disabled={saving}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <Typography paragraph sx={{ mt: 2 }}>
            Le email non verranno inviate per questo impianto perché non è un
            impianto Attivo
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

function ImpostazioneNotifica({
  notifica,
  statoNotificaImpianto: savedState,
  saveStatoNotificaImpianto,
  disabled,
}: {
  notifica: Notifica;
  statoNotificaImpianto: boolean | undefined;
  saveStatoNotificaImpianto: (
    idNotifica: number,
    state: boolean | undefined
  ) => Promise<boolean>;
  disabled?: boolean;
}) {
  const [state, setState] = useState<boolean | undefined>(savedState);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (savedState !== state) {
      setState(savedState);
    }
  }, [state, savedState]);

  async function doChangeState(newState: NotificaImpiantoStateLabel) {
    const booleanValue = stateLabelToBoolean(newState);
    if (booleanValue === state) {
      return;
    }

    setSaving(true);
    const success = await saveStatoNotificaImpianto(notifica.id, booleanValue);
    setSaving(false);
    if (!success) {
      toast.error("Salvataggio non riuscito");
      setState(savedState);
    }
  }

  return (
    <tr>
      <td width="300px">
        <Typography sx={{ fontSize: "0.9em" }}>{notifica.title}</Typography>
      </td>
      <td style={{ position: "relative" }}>
        <ToggleButtonGroup
          value={booleanStateToLabel(state)}
          exclusive
          onChange={(_, value) => doChangeState(value)}
          size="small"
          disabled={disabled || saving}
        >
          <ToggleButton
            sx={generateSxProps(darkBackgroundColors.green)}
            value="Attivata"
          >
            Attivata
          </ToggleButton>
          <ToggleButton
            sx={generateSxProps(darkBackgroundColors.red)}
            value="Disattivata"
          >
            Disattivata
          </ToggleButton>
          <ToggleButton
            value="Default"
            sx={generateSxProps(darkBackgroundColors.yellow)}
          >
            Default{" "}
            <Chip
              size="small"
              label={notifica.active ? "Attivata" : "Disattivata"}
              sx={{
                fontSize: "0.75em",
                width: 70,
                marginLeft: 1,
                backgroundColor: (theme) =>
                  lighten(
                    typeof state === "undefined"
                      ? theme.palette.warning.light
                      : theme.palette.grey[500],
                    0.8
                  ),
                color: () =>
                  (notifica.active ? colors.green : colors.red).color,
              }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
        {saving && (
          <div
            style={{
              position: "absolute",
              right: -20,
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress size={16} />
          </div>
        )}
      </td>
    </tr>
  );
}

type NotificaImpiantoStateLabel = "Attivata" | "Disattivata" | "Default";

function booleanStateToLabel(
  state: boolean | undefined
): NotificaImpiantoStateLabel {
  if (state === true) {
    return "Attivata";
  } else if (state === false) {
    return "Disattivata";
  }
  return "Default";
}

function stateLabelToBoolean(
  label: NotificaImpiantoStateLabel
): boolean | undefined {
  if (label === "Attivata") {
    return true;
  } else if (label === "Disattivata") {
    return false;
  }
  return undefined;
}
