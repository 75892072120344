import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import {
  AziendaIcon,
  ComuneIcon,
  DenominazioneIcon,
  DichiarazioniAd1Icon,
  EditIcon,
  GuideETutorialIcon,
  IndirizzoIcon,
} from "src/components/elements/AppIcons";
import React, { useMemo } from "react";
import { ImpiantoGreen } from "energix-types/src/Impianto";
import { Azienda } from "energix-types/src/Azienda";
import { useEditImpiantoGreenDialog } from "src/components/features/green/EditImpiantoGreenDialog";
import { UpdateImpiantoGreenFunction } from "src/actions/green/updateImpiantoGreen";
import { Link } from "react-router-dom";
import { datiByCodiceCatastale } from "energix-types/src/constants/datiComuniIstat";
import {
  ContatoreDto,
  EdistribuzioneContatoreDto,
  EdistribuzioneCredenzialiDto,
  GSEContatoreDto,
  GSECredenzialiDto,
} from "src/orval/models";

export type InfoImpiantoCardProps = {
  impianto: ImpiantoGreen;
  azienda: Azienda;
  aziende: Azienda[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  contatori: ContatoreDto[];
  updateImpiantoGreen: UpdateImpiantoGreenFunction;
  linkAzienda: string;
  linkImpianto?: string;
  onOpenImpianto?: () => void;
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto | null;
  gseCredenzali: GSECredenzialiDto | null;
  listaEdistribuzioneCredenziali: EdistribuzioneCredenzialiDto[];
  listaGseCredenzali: GSECredenzialiDto[];

  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void;
  openGSECredenzialiDialog: (azienda: Azienda) => void;
};

const iconStyle = {
  color: (theme: Theme) => theme.palette.grey[600],
};

const labelStyle = {
  color: (theme: Theme) => theme.palette.grey[600],
  display: "inline",
};

export default function InfoImpiantoCard({
  impianto,
  azienda,
  aziende,
  contatoriEdistribuzione,
  contatoriGSE,
  contatori,
  updateImpiantoGreen,
  linkAzienda,
  linkImpianto,
  onOpenImpianto,
  edistribuzioneCredenziali,
  gseCredenzali,
  listaEdistribuzioneCredenziali,
  listaGseCredenzali,

  openEdistribuzioneCredenzialiDialog,
  openGSECredenzialiDialog,
}: InfoImpiantoCardProps) {
  const { editImpiantoDialog, openEditImpiantoGreenDialog } =
    useEditImpiantoGreenDialog(
      aziende,
      contatori,
      contatoriEdistribuzione,
      contatoriGSE,
      updateImpiantoGreen,
      listaEdistribuzioneCredenziali,
      listaGseCredenzali,
      openEdistribuzioneCredenzialiDialog,
      openGSECredenzialiDialog
    );

  const datiComune =
    datiByCodiceCatastale[impianto.codiceCatastaleImpianto ?? ""];

  const pods = useMemo(() => {
    return contatori.map((c) => c.pod);
  }, [contatori]);

  return (
    <Card style={{ height: "100%" }}>
      {editImpiantoDialog}
      <CardHeader
        title="Impianto"
        action={
          <>
            {(linkImpianto || onOpenImpianto) && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DichiarazioniAd1Icon />}
                onClick={onOpenImpianto}
                {...(linkImpianto
                  ? {
                      component: Link,
                      to: linkImpianto,
                    }
                  : {})}
              >
                Apri Dichiarazioni AD-1
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <DenominazioneIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Azienda: </Typography>
              <strong>{azienda.richiedenteNome}</strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DenominazioneIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Denominazione: </Typography>
              <strong>{impianto.name}</strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ComuneIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Comune sede: </Typography>
              <strong>{datiComune?.comune}</strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <IndirizzoIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Indirizzo: </Typography>
              <strong>{impianto.indirizzoImpianto}</strong>
            </ListItemText>
          </ListItem>
          {/*<ListItem>*/}
          {/*  <ListItemIcon>*/}
          {/*    <CodiceDittaIcon sx={iconStyle} />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText>*/}
          {/*    <Typography sx={labelStyle}>Identificativo GSE: </Typography>*/}
          {/*    <strong>TODO</strong>*/}
          {/*  </ListItemText>*/}
          {/*</ListItem>*/}

          {/*<ListItem>*/}
          {/*  <ListItemIcon>*/}
          {/*    <DenominazioneIcon sx={iconStyle} />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText>*/}
          {/*    <Typography sx={labelStyle}>Codice POD: </Typography>*/}
          {/*    <strong>TODO</strong>*/}
          {/*  </ListItemText>*/}
          {/*</ListItem>*/}
          <ListItem>
            <ListItemIcon>
              <GuideETutorialIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={labelStyle}>E-Distribuzione: </Typography>
                <IntegrazioneGestoreBadge
                  hasCredenziali={!!azienda.credenzialiEdistribuzioneId}
                  hasAtLeastOneContatore={
                    contatoriEdistribuzione.findIndex((ec) =>
                      pods.includes(ec.pod)
                    ) >= 0
                  }
                  ultimoDownloadStato={
                    edistribuzioneCredenziali?.ultimoDownloadTask?.stato ?? null
                  }
                  ultimoDownloadStatoError={
                    edistribuzioneCredenziali?.ultimoDownloadTask?.errore ??
                    null
                  }
                />
              </Stack>
              {/* <FormHelperText
                error={!!azienda.erroreUltimoDownloadEdistribuzione}
              >
                {azienda.erroreUltimoDownloadEdistribuzione}
              </FormHelperText> */}
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <GuideETutorialIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={labelStyle}>GSE: </Typography>
                <IntegrazioneGestoreBadge
                  hasCredenziali={!!azienda.credenzialiGSEId}
                  hasAtLeastOneContatore={
                    contatoriGSE.findIndex((ec) => pods.includes(ec.pod)) >= 0
                  }
                  ultimoDownloadStato={
                    gseCredenzali?.ultimoDownloadTask?.stato ?? null
                  }
                  ultimoDownloadStatoError={
                    gseCredenzali?.ultimoDownloadTask?.errore ?? null
                  }
                />
              </Stack>
              {/* <FormHelperText error={!!azienda.erroreUltimoDownloadGse}>
                {azienda.erroreUltimoDownloadGse}
              </FormHelperText> */}
            </ListItemText>
          </ListItem>
        </List>

        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 1 }}
          spacing={1}
        >
          <Button
            variant="outlined"
            color="success"
            startIcon={<EditIcon />}
            onClick={() => openEditImpiantoGreenDialog(impianto)}
          >
            Modifica Impianto
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AziendaIcon />}
            component={Link}
            to={linkAzienda}
          >
            Apri Azienda
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

function IntegrazioneGestoreBadge({
  hasCredenziali,
  hasAtLeastOneContatore,
  ultimoDownloadStato,
  ultimoDownloadStatoError,
}: {
  hasCredenziali: boolean;
  hasAtLeastOneContatore: boolean;
  ultimoDownloadStato: "in_coda" | "in_corso" | "successo" | "errore" | null;
  ultimoDownloadStatoError: string | null;
}) {
  const hasError = ultimoDownloadStato === "errore" && ultimoDownloadStatoError;

  const text = hasError
    ? `Errore (${ultimoDownloadStatoError})`
    : !hasCredenziali
    ? "Credenziali non inserite"
    : !hasAtLeastOneContatore
    ? "POD non mappati"
    : "Configurato";

  const color = hasError
    ? "error"
    : !hasCredenziali
    ? "warning"
    : !hasAtLeastOneContatore
    ? "info"
    : "success";

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Chip
        sx={{
          maxWidth: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          marginRight: 1,
        }}
        size="small"
        color={color}
        label={<strong>{text}</strong>}
      />
      {ultimoDownloadStato === "in_coda" && (
        <>
          <CircularProgress size={20} />
          <Typography>Download in coda</Typography>
        </>
      )}

      {ultimoDownloadStato === "in_corso" && (
        <>
          <CircularProgress size={20} />
          <Typography>Download in corso</Typography>
        </>
      )}
    </Stack>
  );
}
