import { Box, CardHeader, Divider, Tab, Tabs } from "@mui/material";
import React, { ReactNode } from "react";

export type TabsCardHeaderProps = {
  tab: number;
  tabs: ({ label: ReactNode } | { divider: true })[];
  onChange: (number: number) => void;
  action?: React.ReactNode;
};

export default function TabsCardHeader({
  tab,
  tabs,
  onChange,
  action,
}: TabsCardHeaderProps) {
  return (
    <CardHeader
      sx={{
        backgroundColor: "#F4F6F8",
        paddingTop: 0,
      }}
      title={
        <Box display="flex" alignItems="center">
          <Tabs
            value={tab}
            onChange={(e, value) => onChange(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((obj, index) => {
              if (isDivider(obj)) {
                return (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{ marginLeft: 0, marginRight: 20 }}
                  />
                );
              }
              const tabName = obj.label;
              return (
                <Tab
                  key={typeof tabName === "string" ? tabName : index}
                  value={index}
                  label={tabName}
                />
              );
            })}
          </Tabs>
        </Box>
      }
      action={action}
    />
  );
}

export function isDivider(
  el: { label: ReactNode } | { divider: true }
): el is { divider: true } {
  return (el as any).divider;
}
