import { each, toPairs, sortBy, pick } from "lodash";

const adminAttributesToKeep = [
  "hasSendDate",
  "target",
  "statoRendicontazione",
  "tipoImpianto",
  "tipoContratto",
  "specialNotification",
  "title",
  "description",
];

export default function getNotifications(
  partnerNotifications,
  defaultNotifications
) {
  const byIdOrParentId = {};

  const addNotification = (notification, keepAdminAttributes = false) => {
    if (typeof notification.toJSON === "function") {
      notification = notification.toJSON();
    }

    const idOrParentId = notification.parentNotificationId || notification.id;

    const existingNotification = byIdOrParentId[idOrParentId];
    if (keepAdminAttributes && existingNotification) {
      notification = {
        ...notification,
        ...pick(existingNotification, adminAttributesToKeep),
      };
    }

    byIdOrParentId[idOrParentId] = notification;
  };

  each(defaultNotifications, (n) => addNotification(n));
  each(partnerNotifications, (n) => addNotification(n, true));

  const pairs = toPairs(byIdOrParentId);
  return sortBy(pairs, (p) => parseInt(p[0], 10)).map((p) => p[1]);
}
