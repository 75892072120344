import { CALL_API } from "../middleware/api";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";

export const USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST =
  "USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS =
  "USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE =
  "USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE";

export const resetPassword = (params) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        USER_RESET_PASSWORD_REQUEST,
        USER_RESET_PASSWORD_SUCCESS,
        USER_RESET_PASSWORD_FAILURE,
      ],
      endpoint: "/api/resetPassword",
      method: "POST",
      body: params,
    },
  });
};

export const resetPasswordTokenPage = (params) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST,
        USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS,
        USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE,
      ],
      endpoint: "/api/resetPassword/newPassword",
      method: "POST",
      body: params,
    },
  });
};
