import { CALL_API } from "../middleware/api";
import { verifyPermlinkForUser } from "common/utils";

export const SEND_VERIFY_EMAIL_REQUEST = "SEND_VERIFY_EMAIL_REQUEST";
export const SEND_VERIFY_EMAIL_SUCCESS = "SEND_VERIFY_EMAIL_SUCCESS";
export const SEND_VERIFY_EMAIL_FAILURE = "SEND_VERIFY_EMAIL_FAILURE";

export const resendVerifyEmail = () => (dispatch, getState) => {
  const state = getState();
  const tokenPermlink =
    state.verify.tokenPermlink ||
    (state.auth.user && verifyPermlinkForUser(state.auth.user));
  return dispatch({
    [CALL_API]: {
      types: [
        SEND_VERIFY_EMAIL_REQUEST,
        SEND_VERIFY_EMAIL_SUCCESS,
        SEND_VERIFY_EMAIL_FAILURE,
      ],
      endpoint: "/api/verify/send",
      method: "POST",
      body: { tokenPermlink },
    },
  });
};
