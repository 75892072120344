import React from "react";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import GseContoEnergiaTable from "src/components/features/green/GseContoEnergiaTable";
import TableContainerCard from "src/components/TableContainerCard";
import { GSEFotovoltaicoCorrispettivi } from "energix-types/src/GSE";
import { useFilterByPod } from "src/components/features/green/filterByPod";
import { Link as RouterLink } from "react-router-dom";
import { DownloadIcon } from "src/components/elements/AppIcons";

export type GseContoEnergiaTableCardProps = {
  riepilogo?: boolean;
  contoEnergiaList: GSEFotovoltaicoCorrispettivi[];
  dettagliLink?: string;
  onDownloadXls?: () => void;
};

export default function GseContoEnergiaTableCard({
  riepilogo = false,
  contoEnergiaList,
  dettagliLink,
  onDownloadXls,
}: GseContoEnergiaTableCardProps) {
  const { TabsComponent, filteredData } = useFilterByPod(
    !riepilogo,
    contoEnergiaList
  );

  return (
    <Card>
      {TabsComponent}
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Conto Energia</Typography>
          {onDownloadXls && (
            <Button
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
              onClick={onDownloadXls}
            >
              Esporta .XLSX
            </Button>
          )}
        </Stack>

        <TableContainerCard>
          <GseContoEnergiaTable data={filteredData} riepilogo={riepilogo} />
        </TableContainerCard>
        {riepilogo && dettagliLink && (
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to={dettagliLink}
            >
              Apri dettaglio
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
