import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import React from "react";

export type AppCheckboxProps = {
  value: boolean;
  onChange?: (v: boolean) => void;
  label: string;
  validationError?: string;
  size?: "small" | "medium";
  disabled?: boolean;
};

export default function AppCheckbox({
  value,
  onChange,
  label,
  validationError,
  size,
  disabled = false,
}: AppCheckboxProps) {
  return (
    <FormControl size={size} error={!!validationError}>
      <FormControlLabel
        disableTypography={size === "small"}
        control={
          <Checkbox
            size={size}
            checked={value}
            onChange={() => onChange?.(!value)}
            disabled={disabled}
          />
        }
        label={
          size === "small" ? (
            <Typography fontSize="small">{label}</Typography>
          ) : (
            label
          )
        }
      />
      {validationError && <FormHelperText>{validationError}</FormHelperText>}
    </FormControl>
  );
}
