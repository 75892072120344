import { GSERidCorrispettivi } from "energix-types/src/GSE";
import { useFilterByPod } from "src/components/features/green/filterByPod";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import TableContainerCard from "src/components/TableContainerCard";
import React from "react";
import GseRidTable from "src/components/features/green/GseRidTable";
import { Link as RouterLink } from "react-router-dom";
import { DownloadIcon } from "src/components/elements/AppIcons";

export type GseRidTableCardProps = {
  riepilogo?: boolean;
  ridList: GSERidCorrispettivi[];
  dettagliLink?: string;
  onDownloadXls?: () => void;
};

export default function GseRidTableCard({
  riepilogo = false,
  ridList,
  dettagliLink,
  onDownloadXls,
}: GseRidTableCardProps) {
  const { TabsComponent, filteredData } = useFilterByPod(!riepilogo, ridList);

  return (
    <Card>
      {TabsComponent}
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            Corrispettivo Ritiro Dedicato (RID)
          </Typography>
          {onDownloadXls && (
            <Button
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
              onClick={onDownloadXls}
            >
              Esporta .XLSX
            </Button>
          )}
        </Stack>

        <TableContainerCard>
          <GseRidTable data={filteredData} riepilogo={riepilogo} />
        </TableContainerCard>
        {riepilogo && dettagliLink && (
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to={dettagliLink}
            >
              Apri dettaglio
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
