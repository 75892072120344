import { Notifica } from "energix-types/src/Notifica";
import getNotifications from "energix-old/src/common/notifications/getNotifications";

export function getMergedNotifiche(
  notifichePartner: Notifica[],
  notificheAdmin: Notifica[]
): Notifica[] {
  return getNotifications(notifichePartner, notificheAdmin);
}

export function isNotificaManuale(notifica: Notifica) {
  return (
    notifica.specialNotification === "compila_letture" ||
    notifica.specialNotification === "firma_xml"
  );
}
