import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormState,
} from "react-hook-form";
import NumberInput from "src/components/elements/NumberInput";

export type AppControlledFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  label?: string;
  multiline?: boolean;
  help?: ReactNode;
  disabled?: boolean;
  type?: "text" | "password" | "number";
  flex?: string;
  upperCase?: boolean;
};

export default function AppControlledFormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  multiline = false,
  help,
  disabled = false,
  type = "text",
  control,
  rules,
  flex,
  upperCase = false,
}: AppControlledFormFieldProps<TFieldValues, TName>) {
  return (
    <Box
      flex={flex}
      sx={{
        "& .MuiInputBase-root": {
          background: "#f8f7f780",
        },
      }}
    >
      {label && (
        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom>{label}</Typography>
          {help}
        </Stack>
      )}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, formState }) => {
          const errorText = getErrorText(formState, name);
          return type === "number" ? (
            <>
              <NumberInput
                value={field.value}
                onChange={(_: any, v: number) => field.onChange(v)}
                fullWidth
                size="small"
                disabled={disabled}
                error={!!errorText}
              />
              {errorText && (
                <Typography color="error" variant="body2">
                  {errorText}
                </Typography>
              )}
            </>
          ) : (
            <TextField
              value={field.value}
              onChange={(e) => {
                if (upperCase) {
                  field.onChange(e.target.value.toUpperCase());
                } else {
                  field.onChange(e.target.value);
                }
              }}
              fullWidth
              size="small"
              multiline={multiline}
              minRows={multiline ? 3 : undefined}
              maxRows={multiline ? 4 : undefined}
              disabled={disabled}
              error={!!errorText}
              helperText={errorText}
              type={type}
            />
          );
        }}
      />
    </Box>
  );
}

export function getErrorText<TFieldValues extends FieldValues>(
  formState: FormState<TFieldValues>,
  name: keyof TFieldValues
): string | null {
  const error = formState.errors[name];
  if (!error) {
    return null;
  }
  if (error.message) {
    return error.message as string;
  }
  return "Campo richiesto";
}
