import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export type AppFormFieldProps = {
  label: string;
  value: string | null;
  onChange?: (v: string) => void;
  multiline?: boolean;
  help?: ReactNode;
  disabled?: boolean;
  validationError?: string;
  type?: "text" | "password" | "number";
  placeholder?: string;
  upperCase?: boolean;
};

function AppFormField({
  label,
  value,
  onChange,
  multiline = false,
  help,
  disabled = false,
  validationError,
  type = "text",
  placeholder,
  upperCase,
}: AppFormFieldProps) {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Typography gutterBottom>{label}</Typography>
        {help}
      </Stack>

      <TextField
        fullWidth
        size="small"
        value={value || ""}
        onChange={(e) =>
          onChange?.(upperCase ? e.target.value.toUpperCase() : e.target.value)
        }
        multiline={multiline}
        minRows={multiline ? 3 : undefined}
        maxRows={multiline ? 4 : undefined}
        disabled={disabled}
        error={!!validationError}
        helperText={validationError}
        type={type}
        placeholder={placeholder}
      />
    </Box>
  );
}

export default React.memo(AppFormField, (oldProps, newProps) => {
  return (
    oldProps.value === newProps.value &&
    oldProps.validationError === newProps.validationError &&
    oldProps.disabled === newProps.disabled &&
    oldProps.onChange === newProps.onChange
  );
});
