export const TipiEDistribuzioneEnergiaMensile = [
  "produzione",
  "autoconsumo",
  "immissione",
  "prelievo",
] as const;

export type TipoEDistribuzioneEnergiaMensile =
  typeof TipiEDistribuzioneEnergiaMensile[number];

export type EDistribuzioneEnergiaMensile = {
  tipo: TipoEDistribuzioneEnergiaMensile;
  anno: number;
  mese: number;
  valore: number;
};
