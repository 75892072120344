export interface ModuliAttivi {
  serviziMassivi?: StatoModuloAttivo;
  dashboardUtenteFinale?: StatoModuloAttivo;
  monitoraggioEdistribuzione?: StatoModuloAttivo;
  monitoraggioGse?: StatoModuloAttivo;
  reportEdistribuzione?: StatoModuloAttivo;
  reportGSE?: StatoModuloAttivo;
  scadenze?: StatoModuloAttivo;
  mailingAutomatico?: StatoModuloAttivo;
  alertEdistribuzione?: StatoModuloAttivo;
}

export type StatoModuloAttivo =
  | undefined
  | "non_attivo"
  | "attivo"
  | "attivo_rinnovo_automatico";

export const moduliAttiviByKey: {
  [key in ModuloAttivoKey]: {
    label: string;
    cannoneFissoPerPartner: boolean;
  };
} = {
  serviziMassivi: {
    label: "Servizi massivi",
    cannoneFissoPerPartner: true,
  },
  dashboardUtenteFinale: {
    label: "Dashboard utente finale",
    cannoneFissoPerPartner: true,
  },
  monitoraggioEdistribuzione: {
    label: "Monitoraggio Energia",
    cannoneFissoPerPartner: true,
  },
  reportEdistribuzione: {
    label: "Emissione di report periodici E-Distribuzione",
    cannoneFissoPerPartner: false,
  },
  reportGSE: {
    label: "Emissione di report periodici GSE",
    cannoneFissoPerPartner: false,
  },
  monitoraggioGse: {
    label: "Monitoraggio GSE",
    cannoneFissoPerPartner: true,
  },
  scadenze: {
    label: "Scadenze",
    cannoneFissoPerPartner: true,
  },
  mailingAutomatico: {
    label: "Mailing automatico",
    cannoneFissoPerPartner: true,
  },
  alertEdistribuzione: {
    label: "Indicatori di performance degli impianti ed Alert",
    cannoneFissoPerPartner: true,
  },
};

export const moduliAttivi = Object.entries(moduliAttiviByKey).map(
  ([key, value]) => ({
    key: key as keyof ModuliAttivi,
    ...value,
  })
);

export type ModuloAttivoKey = keyof ModuliAttivi;
