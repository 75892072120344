export function isGenerazioneXmlEInvioDisabilitatoPerNuovoAnno(
  anno: number,
  isAdmin: boolean
) {
  if (isAdmin) {
    return true;
  }

  anno = anno - 1; //fix anno

  //TODO: aggiornare questo anno tutti gli anni quando è si fanno le modifiche e i controlli con i cambiamenti delle dogane!
  const disabilitato = 2024;

  return anno >= disabilitato;
}

// ritornare l'anno in cui si vuole fare la rendicontazione
// quindi quando si è iniziato era 2019, perche si voleva rendicontare l'anno 2018

export function getAnnoRendicontazioneCorrente() {
  const date = new Date();
  let y = date.getFullYear();

  return y;
}

// usato per esempio per far vedere che c'è una nuova rendicontazione da fare
export function getAnnoRendicontazioneDaConsiderareUltima() {
  const date = new Date();
  let y = date.getFullYear();

  // farei che se siamo nel 2019 (fino a settembre) si presuppone che vuole inserire quelli del 2018 ancora... poi dal 2020 (anche ottobre 2019 in poi) vorrà inserire quelli del 2019

  const m = date.getMonth() + 1;
  if (m >= 9) {
    y++;
  }

  return y;
}

const _anniRendicontazioni = [];
const _anniRendicontazioniWithNext = [];
const _anniRendicontazioniForPartners = [];

const ultimoAnnoFattibile = new Date().getFullYear();
const ultimoAnnoRendicontazione = getAnnoRendicontazioneDaConsiderareUltima();
const ultimoAnnoRendicontazioneForPartners = ultimoAnnoRendicontazione + 5;
const minAnno = 2015;

for (let anno = minAnno; anno <= ultimoAnnoFattibile; anno++) {
  _anniRendicontazioni.push(anno);
}
for (let anno = minAnno; anno <= ultimoAnnoRendicontazione; anno++) {
  _anniRendicontazioniWithNext.push(anno);
}
for (let anno = minAnno; anno <= ultimoAnnoRendicontazioneForPartners; anno++) {
  _anniRendicontazioniForPartners.push(anno);
}

export const anniRendicontazioni = _anniRendicontazioni;

export const anniRendicontazioniWithNext = _anniRendicontazioniWithNext;

export const anniRendicontazioniForPartners = _anniRendicontazioniForPartners;
