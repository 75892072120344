import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { comuni } from "energix-types/src/constants/datiComuniIstat";
import React from "react";

export type SelectIstatComuneProps = {
  value: string | null;
  onChange: (v: string | null) => void;
  label?: string;
  validationError?: string;
  flex?: string;
};

export default function SelectIstatComune({
  value,
  onChange,
  label,
  validationError,
  flex,
}: SelectIstatComuneProps) {
  return (
    <Stack flex={flex} direction="column" spacing={1}>
      {label && <Typography>{label}</Typography>}
      <Autocomplete
        disablePortal
        options={comuni}
        fullWidth
        renderInput={(params) => (
          <TextField
            error={!!validationError}
            helperText={validationError}
            {...params}
          />
        )}
        value={value || ""}
        onChange={(_, v) => onChange(v)}
        size="small"
      />
    </Stack>
  );
}
