import { Dichiarazione } from "energix-types/src/Dichiarazione";
import { ImpiantoType } from "energix-types/src/DefinizioneImpianto";
import { Impianto } from "energix-types/src/Impianto";
import { Notifica } from "energix-types/src/Notifica";

export function isDichiarazioneBozza(dichiarazione: Dichiarazione) {
  return !!dichiarazione.reportInfo?.statoReport;
}

export function canSignDichiarazione(
  dichiarazione: Dichiarazione,
  impiantoType: ImpiantoType
) {
  const canDoDichiarazione = impiantoType !== "IMPIANTO_DI_VENDITA";
  return !isDichiarazioneBozza(dichiarazione) && canDoDichiarazione;
}

// TODO: in notification manager c'è la stessa cosa, prima o poi magari fare refactoring?
// altrimenti se si modifica una, modificare anche l'altra
export function isImpiantoAttivoForNotification(impianto: Impianto) {
  const { installationState } = impianto;
  const disabled =
    installationState === "Inattivo" || installationState === "Archiviato";
  return !disabled;
}

// TODO: in notification manager c'è la stessa cosa, prima o poi magari fare refactoring?
// altrimenti se si modifica una, modificare anche l'altra
// 'undefined' = usa notification.active per il valore di default
export function isNotificationEnabled(
  impianto: Impianto,
  notification: Notifica
): boolean | undefined {
  const { notificationsEnabled } = impianto;

  const overriddenState = notificationsEnabled[notification.id];
  if (typeof overriddenState === "boolean") {
    return overriddenState;
  }

  // check parent too
  const overriddenState2 = notification.parentNotificationId
    ? notificationsEnabled[notification.parentNotificationId]
    : undefined;
  if (typeof overriddenState2 === "boolean") {
    return overriddenState2;
  }

  return undefined;
}
