import React from "react";
import { DialogActions, Theme } from "@mui/material";

export type AppDialogActionsProps = {
  children: React.ReactNode;
};

const dialogActionsStyle = { padding: "12px 0 0 0" };

const dialogActionsSx = {
  margin: (theme: Theme) => theme.spacing(2),
  borderTop: (theme: Theme) => `2px solid ${theme.palette.grey[50016]}`,
};

export default function AppDialogActions({ children }: AppDialogActionsProps) {
  return (
    <DialogActions
      disableSpacing
      style={dialogActionsStyle}
      sx={dialogActionsSx}
    >
      {children}
    </DialogActions>
  );
}
